import React, { useState, useEffect } from 'react';
import './variables.css';
import './global.css';
import Footer from './FooterNew';
//import Mainnavigation from './NavigationNew';
import Mainnavigation from './NavigationHomeNew';
import Spinner from './common/Spinner';

import { Box } from '@mui/material';

const Layout = ({ subcomponent, location, toastBarInfo, bgColor, partnerInfo }) => {
    const [pageLoading, setPageLoading] = useState(true);
    bgColor = bgColor && bgColor ? bgColor : '#F7F1FD';

    useEffect(() => {
        setTimeout(() => {
            setPageLoading(false);
        }, 100);
    }, []);

    return pageLoading ? (
        <Spinner />
    ) : (
        <>
            <Mainnavigation location={location} toastBarInfo={toastBarInfo} partnerInfo={partnerInfo} />
            <Box component="main" sx={{ background: bgColor }}>
                {subcomponent}
            </Box>
            <Footer location={location} partnerInfo={partnerInfo} />
        </>
    );
};

export default Layout;
