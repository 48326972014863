import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import Seo from '../components/SeoComponent';
import Layout from '../components/LayoutComponent';
import { Box, Grid, Container } from '@mui/material';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { Link } from 'gatsby';
import MiniAdTemplate from './MiniAdTemplate';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import sanitizeHtml from 'sanitize-html';
import { articles2 } from '../components/blog/ArticleContent';

const Bold = ({ children }) => <span className="bold">{children}</span>;
const Text = ({ children }) => <p className="align-center">{children}</p>;

// const domainUrl = process.env.GATSBY_DOMAIN_URL || '';
const domainUrl = '';

const options = {
    renderMark: {
        [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    },
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
        [BLOCKS.EMBEDDED_ASSET]: (node) => {
            return (
                <>
                    <h2>Embedded Asset</h2>
                    <pre>
                        <code>{JSON.stringify(node, null, 2)}</code>
                    </pre>
                </>
            );
        },
    },
};

class CategoryArticleTemplate extends React.Component {
    render() {
        const articleData = get(this.props, 'data.commonArticleData');
        // console.log(articleData);
        const image = getImage(articleData.nodes[0].articleImage);
        //console.log(this.props.pageContext.articleType)

        const articleType =
            this.props && this.props.pageContext && this.props.pageContext.articleType
                ? this.props.pageContext.articleType
                : '';

        console.log(articleType);

        // const postTitle = post && post.title ? sanitizeHtml(post.title) : '';
        // const postPrice = post && post.adBlockDynamicPrice ? sanitizeHtml(post.adBlockDynamicPrice) : '';
        // const postPromoText = post && post.adBlockPromoText ? post.adBlockPromoText : '';
        // const postDescription = post && post.adBlockDescription ? post.adBlockDescription : '';
        // const metaTitle = post.title + ' | Articles | Asurion Mobile+';
        // const metaDescription = metaTags && metaTags.description ? metaTags.description : '';
        // const ogImage = metaTags && metaTags.ogImage ? metaTags.ogImage.file.url : '';

        // const postSlug = post.title.replaceAll(' ', '-').replaceAll(':', '');

        // const canonicalUrl =
        //     this.props && this.props.pageContext && this.props.pageContext.canonicalPath
        //         ? domainUrl + this.props.pageContext.canonicalPath
        //         : domainUrl + this.props.path;

        // console.log('canonical', canonicalUrl);

        //const result = articles2.articles.find(({ associatedDevice }) => associatedDevice === postSlug);

        return (
            <Layout
                location={'location'}
                subcomponent={
                    <>
                        <Seo title={''} description={''} ogImage={''} image={''} canonical={''} />
                        <Container maxWidth="ssm" fixed className="ltArticleContainer">
                            <Box sx={{ width: '100%' }}>
                                {!articleType && (
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                        style={{ margin: 'auto', borderRadius: '16px' }}
                                    >
                                        <Grid
                                            item
                                            sx={{ flexGrow: 1 }}
                                            style={{
                                                backgroundColor: '#F0F0F5',
                                                borderRadius: '16px',
                                                marginBottom: '3%',
                                            }}
                                        >
                                            {articleData.nodes[0].articleImage.file.url && (
                                                <div>
                                                    <GatsbyImage
                                                        image={image}
                                                        style={{ borderRadius: '16px 16px 0 0', marginBottom: '2%' }}
                                                        alt={articleData.nodes[0].articleTitle}
                                                    />
                                                    <div style={{ borderRadius: '16px' }}>
                                                        <Box
                                                            sx={{
                                                                fontWeight: 'bold',
                                                                padding: '0 3%',
                                                                fontSize: '18px',
                                                            }}
                                                            pt="3px"
                                                        >
                                                            {articleData.nodes[0].articleTitle}
                                                        </Box>
                                                        <Box sx={{ fontSize: '16px', padding: '0 3%' }}>
                                                            {articleData.nodes[0].articleShortDescription &&
                                                                renderRichText(
                                                                    articleData.nodes[0].articleShortDescription,
                                                                    options
                                                                )}
                                                        </Box>
                                                        <Box sx={{ fontSize: '14px', padding: '0 3% 3%' }}>
                                                            <Link
                                                                className="linkArticle"
                                                                to={
                                                                    domainUrl +
                                                                    `/articles/${articleData.nodes[0].category
                                                                        .toLowerCase()
                                                                        .replaceAll(' ', '-')
                                                                        .replaceAll(
                                                                            ':',
                                                                            ''
                                                                        )}/${articleData.nodes[0].subCategory
                                                                        .toLowerCase()
                                                                        .replaceAll(' ', '-')
                                                                        .replaceAll(
                                                                            ':',
                                                                            ''
                                                                        )}/${articleData.nodes[0].articleTitle
                                                                        .toLowerCase()
                                                                        .replaceAll(' ', '-')
                                                                        .replace(/[?=]/g, '')
                                                                        .replaceAll(':', '')}`
                                                                }
                                                            >
                                                                Read More
                                                            </Link>
                                                        </Box>
                                                    </div>
                                                </div>
                                            )}
                                        </Grid>
                                        {/* <Grid>
                                            <MiniAdTemplate>
                                                As an Asurion Mobile+ member you get access to hundreds of articles,
                                                videos and virtual / personal assistance.{' '}
                                                <a href="https://www.asurion.com.au" className="linkArticle">
                                                    Find out more
                                                </a>
                                            </MiniAdTemplate>
                                        </Grid> */}
                                        <Grid>
                                            {typeof result !== 'undefined' && (
                                                <Box sx={{ fontWeight: 'bold', padding: '3% 0', fontSize: '1.25rem' }}>
                                                    Phone accessories for you
                                                </Box>
                                            )}
                                            {articleData.nodes.slice(1).map((nodes, index) => (
                                                <Grid
                                                    item
                                                    sx={{ flexGrow: 1 }}
                                                    mt={'10%'}
                                                    style={{ backgroundColor: '#F0F0F5', borderRadius: '16px' }}
                                                >
                                                    {articleData.nodes[index + 1].articleImage.file.url && (
                                                        <div>
                                                            <GatsbyImage
                                                                image={getImage(
                                                                    articleData.nodes[index + 1].articleImage
                                                                )}
                                                                style={{
                                                                    borderRadius: '16px 16px 0 0',
                                                                    paddingBottom: '3%',
                                                                }}
                                                                alt={articleData.nodes[index + 1].articleTitle}
                                                            />

                                                            <div style={{ borderRadius: '16px' }}>
                                                                <Box
                                                                    sx={{
                                                                        fontWeight: 'bold',
                                                                        padding: '3% 3% 0',
                                                                        fontSize: '18px',
                                                                    }}
                                                                    pt="3px"
                                                                >
                                                                    {articleData.nodes[index + 1].articleTitle}
                                                                </Box>
                                                                <Box sx={{ fontSize: '16px', padding: '0 3%' }}>
                                                                    {articleData.nodes[index + 1]
                                                                        .articleShortDescription &&
                                                                        renderRichText(
                                                                            articleData.nodes[index + 1]
                                                                                .articleShortDescription,
                                                                            options
                                                                        )}
                                                                </Box>
                                                                <Box sx={{ fontSize: '14px', padding: '0 3% 3%' }}>
                                                                    <Link
                                                                        to={
                                                                            domainUrl +
                                                                            `/articles/${articleData.nodes[
                                                                                index + 1
                                                                            ].category
                                                                                .toLowerCase()
                                                                                .replaceAll(' ', '-')
                                                                                .replaceAll(
                                                                                    ':',
                                                                                    ''
                                                                                )}/${articleData.nodes[
                                                                                index + 1
                                                                            ].subCategory
                                                                                .toLowerCase()
                                                                                .replaceAll(' ', '-')
                                                                                .replaceAll(
                                                                                    ':',
                                                                                    ''
                                                                                )}/${articleData.nodes[
                                                                                index + 1
                                                                            ].articleTitle
                                                                                .toLowerCase()
                                                                                .replaceAll(' ', '-')
                                                                                .replace(/[?=]/g, '')
                                                                                .replaceAll(':', '')}`
                                                                        }
                                                                        className="linkArticle"
                                                                    >
                                                                        Read More
                                                                    </Link>
                                                                </Box>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                )}
                                {articleType && (
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                        style={{ margin: 'auto', borderRadius: '16px' }}
                                    >
                                        <Grid
                                            item
                                            sx={{ flexGrow: 1 }}
                                            style={{
                                                backgroundColor: '#F0F0F5',
                                                borderRadius: '16px',
                                                marginBottom: '3%',
                                            }}
                                        >
                                            {articles2.articles[0].articleImage && (
                                                <div>
                                                    {/* <GatsbyImage
                                                    image={articles2.articles[0].bannerImage}
                                                    style={{ borderRadius: '16px 16px 0 0', marginBottom: '2%' }}
                                                    alt={articles2.articles[0].title}
                                                /> */}
                                                    <img
                                                        src={articles2.articles[0].articleImage}
                                                        alt=""
                                                        style={{
                                                            borderRadius: '16px 16px 0 0',
                                                            marginBottom: '2%',
                                                            width: '100%',
                                                        }}
                                                    />
                                                    <div style={{ borderRadius: '16px' }}>
                                                        <Box
                                                            sx={{
                                                                fontWeight: 'bold',
                                                                padding: '0 3%',
                                                                fontSize: '18px',
                                                            }}
                                                            pt="3px"
                                                        >
                                                            {articles2.articles[0].title}
                                                        </Box>
                                                        <Box sx={{ fontSize: '16px', padding: '0 3%' }}>
                                                            {articles2.articles[0].description}
                                                        </Box>
                                                        <Box sx={{ fontSize: '14px', padding: '0 3% 3%' }}>
                                                            <Link
                                                                className="linkArticle"
                                                                to={
                                                                    domainUrl +
                                                                    `/articles/tech-accessories/${articles2.articles[0].title
                                                                        .toLowerCase()
                                                                        .replaceAll(' ', '-')
                                                                        .replace(/[?=]/g, '')
                                                                        .replaceAll(':', '')}`
                                                                }
                                                            >
                                                                Read More
                                                            </Link>
                                                        </Box>
                                                    </div>
                                                </div>
                                            )}
                                        </Grid>
                                        <Grid>
                                            <MiniAdTemplate>
                                                As an Asurion Mobile+ member you get access to hundreds of articles,
                                                videos and virtual / personal assistance.{' '}
                                                <a href="https://www.asurion.com.au" className="linkArticle">
                                                    Find out more
                                                </a>
                                            </MiniAdTemplate>
                                        </Grid>
                                        <Grid>
                                            {typeof result !== 'undefined' && (
                                                <Box sx={{ fontWeight: 'bold', padding: '3% 0', fontSize: '1.25rem' }}>
                                                    Phone accessories for you
                                                </Box>
                                            )}
                                            {articles2.articles.slice(1).map((nodes, index) => (
                                                <Grid
                                                    item
                                                    sx={{ flexGrow: 1 }}
                                                    mt={'10%'}
                                                    style={{ backgroundColor: '#F0F0F5', borderRadius: '16px' }}
                                                >
                                                    {articles2.articles[index + 1].articleImage && (
                                                        <div>
                                                            {/* <GatsbyImage
                                                            image={getImage(articles2.articles[index + 1].articleImage)}
                                                            style={{
                                                                borderRadius: '16px 16px 0 0',
                                                                paddingBottom: '3%',
                                                            }}
                                                            alt={articleData.nodes[index + 1].articleTitle}
                                                        /> */}
                                                            <img
                                                                src={articles2.articles[index + 1].articleImage}
                                                                alt=""
                                                                style={{
                                                                    borderRadius: '16px 16px 0 0',
                                                                    marginBottom: '2%',
                                                                    width: '100%',
                                                                }}
                                                            />

                                                            <div style={{ borderRadius: '16px' }}>
                                                                <Box
                                                                    sx={{
                                                                        fontWeight: 'bold',
                                                                        padding: '3% 3% 0',
                                                                        fontSize: '18px',
                                                                    }}
                                                                    pt="3px"
                                                                >
                                                                    {articles2.articles[index + 1].title}
                                                                </Box>
                                                                <Box sx={{ fontSize: '16px', padding: '0 3%' }}>
                                                                    {articles2.articles[index + 1].description}
                                                                </Box>
                                                                <Box sx={{ fontSize: '14px', padding: '0 3% 3%' }}>
                                                                    <Link
                                                                        to={
                                                                            domainUrl +
                                                                            `/articles/tech-accessories/${articles2.articles[
                                                                                index + 1
                                                                            ].title
                                                                                .toLowerCase()
                                                                                .replaceAll(' ', '-')
                                                                                .replace(/[?=]/g, '')
                                                                                .replaceAll(':', '')}`
                                                                        }
                                                                        className="linkArticle"
                                                                    >
                                                                        Read More
                                                                    </Link>
                                                                </Box>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                )}
                            </Box>
                        </Container>
                    </>
                }
            />
        );
    }
}

export default CategoryArticleTemplate;

export const pageQuery1 = graphql`
    query categoryArticleQuery($category: String, $subCategory: String) {
        metaTagsApple: contentfulMetaTags(title: { eq: "Apple Articles" }) {
            title
            robots
            canonical
            description
            ogImage {
                file {
                    url
                    fileName
                }
            }
        }
        metaTagsSamsung: contentfulMetaTags(title: { eq: "Samsung Articles" }) {
            title
            robots
            canonical
            description
            ogImage {
                file {
                    url
                    fileName
                }
            }
        }
        commonArticleData: allContentfulArticleTemplateCommon(
            filter: { node_locale: { eq: "en-US" }, category: { eq: $category }, subCategory: { eq: $subCategory } }
        ) {
            nodes {
                articleTitle
                deviceTitle
                articleShortDescription {
                    raw
                }
                headerImage {
                    file {
                        url
                    }
                    gatsbyImageData(height: 400, layout: CONSTRAINED, width: 700, resizingBehavior: SCALE)
                }
                publishedDate
                articleDetail1 {
                    raw
                }
                articleDetail2 {
                    raw
                }
                articleImage {
                    file {
                        url
                    }
                    gatsbyImageData(height: 400, layout: CONSTRAINED, width: 700, resizingBehavior: SCALE)
                }
                category
                subCategory
            }
        }
    }
`;
