//iphone13
import AppleiPhone13Accessoriesdesk_img1 from '../../images/blogs/Apple_iPhone_13_Accessories/1 Desktop Apple MagSafe battery pack.jpg';
import AppleiPhone13Accessoriesmob_img1 from '../../images/blogs/Apple_iPhone_13_Accessories/1 Mobile Apple MagSafe battery pack.jpg';
import AppleiPhone13Accessoriesmob_img2 from '../../images/blogs/Apple_iPhone_13_Accessories/2 Mobile Apple MagSafe leather wallet.jpg';
import AppleiPhone13Accessoriesmob_img3 from '../../images/blogs/Apple_iPhone_13_Accessories/3 Mobile mophie 3-in-1 stand charger.jpg';
import AppleiPhone13Accessoriesmob_img4 from '../../images/blogs/Apple_iPhone_13_Accessories/4 Mobile Belkin magnetic wireless car charger.jpg';
import AppleiPhone13Accessoriesmob_img5 from '../../images/blogs/Apple_iPhone_13_Accessories/5 Mobile Peak Design bike mount.jpg';
import AppleiPhone13Accessoriesmob_img6 from '../../images/blogs/Apple_iPhone_13_Accessories/6 Mobile Moment creative gear.jpg';
import AppleiPhone13PhoneCasesDesk_img1 from '../../images/blogs/Apple_iPhone_13_Phone_Cases/1 Desktop Urban Armor Gear.jpg';
import AppleiPhone13PhoneCases_img1 from '../../images/blogs/Apple_iPhone_13_Phone_Cases/1 Mobile Urban Armor Gear.jpg';
import AppleiPhone13PhoneCases_img2 from '../../images/blogs/Apple_iPhone_13_Phone_Cases/2 Mobile Supcase.jpg';
import AppleiPhone13PhoneCases_img3 from '../../images/blogs/Apple_iPhone_13_Phone_Cases/3 Mobile Otterbox.jpg';
import AppleiPhone13PhoneCases_img4 from '../../images/blogs/Apple_iPhone_13_Phone_Cases/4 Mobile Speck.jpg';
import AppleiPhone13PhoneCases_img5 from '../../images/blogs/Apple_iPhone_13_Phone_Cases/5 Mobile Casetify.jpg';
import AppleiPhone13PhoneCases_img6 from '../../images/blogs/Apple_iPhone_13_Phone_Cases/6 Mobile Smartish.jpg';
import AppleiPhone13PhoneCases_img7 from '../../images/blogs/Apple_iPhone_13_Phone_Cases/7 Mobile Bellroy.jpg';
import AppleiPhone13PhoneCases_img8 from '../../images/blogs/Apple_iPhone_13_Phone_Cases/8 Mobile Apple.jpg';
import AppleiPhone13ScreenProtectorDesk_img1 from '../../images/blogs/Apple_iPhone_13_Screen_Protector/1 Desktop Supershieldz.jpg';
import AppleiPhone13ScreenProtector_img1 from '../../images/blogs/Apple_iPhone_13_Screen_Protector/1 Mobile Supershieldz.jpg';
import AppleiPhone13ScreenProtector_img2 from '../../images/blogs/Apple_iPhone_13_Screen_Protector/2 Mobile Trianium tempered glass.jpg';
import AppleiPhone13ScreenProtector_img3 from '../../images/blogs/Apple_iPhone_13_Screen_Protector/3 Mobile QHOHQ tempered glass.jpg';
import AppleiPhone13ScreenProtector_img4 from '../../images/blogs/Apple_iPhone_13_Screen_Protector/4 Mobile Spigen EZ Fit GLAS.tR Slim.jpg';
import AppleiPhone13ScreenProtector_img5 from '../../images/blogs/Apple_iPhone_13_Screen_Protector/5 Mobile Belkin ScreenForce TemperedGlass.jpg';
import AppleiPhone13ScreenProtector_img6 from '../../images/blogs/Apple_iPhone_13_Screen_Protector/6 Mobile Belkin UltraGlass.jpg';
import AppleiPhoneSE2022AccessoriesDesk_img1 from '../../images/blogs/Apple_iPhone_SE_2022_Accessories/1 Desktop Scosche MagicGrip Freeflow.png';
import AppleiPhoneSE2022Accessories_img1 from '../../images/blogs/Apple_iPhone_SE_2022_Accessories/1 Mobile Scosche MagicGrip Freeflow.png';
import AppleiPhoneSE2022Accessories_img2 from '../../images/blogs/Apple_iPhone_SE_2022_Accessories/2 Mobile mophie 3-in-1 Wireless Charging Stand.png';
import AppleiPhoneSE2022Accessories_img3 from '../../images/blogs/Apple_iPhone_SE_2022_Accessories/3 Mobile Casetify UV Sanitizer.png';
import AppleiPhoneSE2022Accessories_img4 from '../../images/blogs/Apple_iPhone_SE_2022_Accessories/4 Mobile Razer Kishi Mobile Gaming Controller.png';
import AppleiPhoneSE2022Accessories_img5 from '../../images/blogs/Apple_iPhone_SE_2022_Accessories/5 Mobile Peak Design bike mount.png';
import AppleiPhoneSE2022PhoneCasesDesk_img1 from '../../images/blogs/Apple_iPhone_SE_2022_Phone_Cases/1 Desktop elago Hybrid Clear Case.jpg';
import AppleiPhoneSE2022PhoneCases_img1 from '../../images/blogs/Apple_iPhone_SE_2022_Phone_Cases/1 Mobile elago Hybrid Clear Case.jpg';
import AppleiPhoneSE2022PhoneCases_img2 from '../../images/blogs/Apple_iPhone_SE_2022_Phone_Cases/2 Mobile Spigen Ultra Hybrid.jpg';
import AppleiPhoneSE2022PhoneCases_img3 from '../../images/blogs/Apple_iPhone_SE_2022_Phone_Cases/3 Mobile CASETiFY Stylish skins.jpg';
import AppleiPhoneSE2022PhoneCases_img4 from '../../images/blogs/Apple_iPhone_SE_2022_Phone_Cases/4 Mobile Otterbox Commuter.jpg';
import AppleiPhoneSE2022PhoneCases_img5 from '../../images/blogs/Apple_iPhone_SE_2022_Phone_Cases/5 Mobile Temdan.jpg';
import AppleiPhoneSE2022PhoneCases_img6 from '../../images/blogs/Apple_iPhone_SE_2022_Phone_Cases/6 Mobile Cordking Silicone Ultra Slim.jpg';
import AppleiPhoneSE2022PhoneCases_img7 from '../../images/blogs/Apple_iPhone_SE_2022_Phone_Cases/7 Mobile Apple Silicone Case.jpg';
import AppleiPhoneSE2022PhoneCases_img8 from '../../images/blogs/Apple_iPhone_SE_2022_Phone_Cases/8 Mobile Mous Limitless 2.0.jpg';
import AppleiPhoneSE2022PhoneCases_img9 from '../../images/blogs/Apple_iPhone_SE_2022_Phone_Cases/9 Mobile Apple Leather Case.jpg';
import AppleiPhoneSE2022ScreenProtectorDesk_img1 from '../../images/blogs/Apple_iPhone_SE_2022_Screen_Protector/1 Desktop UAG.jpg';
import AppleiPhoneSE2022ScreenProtector_img1 from '../../images/blogs/Apple_iPhone_SE_2022_Screen_Protector/1 Mobile UAG.jpg';
import AppleiPhoneSE2022ScreenProtector_img2 from '../../images/blogs/Apple_iPhone_SE_2022_Screen_Protector/2 Mobile Speck.jpg';
import AppleiPhoneSE2022ScreenProtector_img3 from '../../images/blogs/Apple_iPhone_SE_2022_Screen_Protector/3 Mobile Spigen.jpg';
import AppleiPhoneSE2022ScreenProtector_img4 from '../../images/blogs/Apple_iPhone_SE_2022_Screen_Protector/4 Mobile SpectreShield.jpg';
import AppleiPhoneSE2022ScreenProtector_img5 from '../../images/blogs/Apple_iPhone_SE_2022_Screen_Protector/5 Mobile Eiger.jpg';
import SamsungGalaxyS22AccessoriesDesk_img1 from '../../images/blogs/Samsung_Galaxy_S22_Accessories/1 Desktop Samsung Super Fast Wireless Charger Duo.jpg';
import SamsungGalaxyS22Accessories_img1 from '../../images/blogs/Samsung_Galaxy_S22_Accessories/1 Mobile Samsung Super Fast Wireless Charger Duo.jpg';
import SamsungGalaxyS22Accessories_img2 from '../../images/blogs/Samsung_Galaxy_S22_Accessories/2 Mobile Samsung Wireless Car Charger.jpg';
import SamsungGalaxyS22Accessories_img3 from '../../images/blogs/Samsung_Galaxy_S22_Accessories/3 Mobile Belkin BOOST.jpg';
import SamsungGalaxyS22Accessories_img4 from '../../images/blogs/Samsung_Galaxy_S22_Accessories/4 Mobile ITFIT UV Steriliser.jpg';
import SamsungGalaxyS22Accessories_img5 from '../../images/blogs/Samsung_Galaxy_S22_Accessories/5 Mobile FitClic Neo Running kit.png';
import SamsungGalaxyS22PhoneCasesDesk_img1 from '../../images/blogs/Samsung_Galaxy_S22_Phone_Cases/1 Desktop Urban Armor Gear.jpg';
import SamsungGalaxyS22PhoneCases_img1 from '../../images/blogs/Samsung_Galaxy_S22_Phone_Cases/1 Mobile Urban Armor Gear.jpg';
import SamsungGalaxyS22PhoneCases_img2 from '../../images/blogs/Samsung_Galaxy_S22_Phone_Cases/2 Mobile Supcase.jpg';
import SamsungGalaxyS22PhoneCases_img3 from '../../images/blogs/Samsung_Galaxy_S22_Phone_Cases/3 Mobile Otterbox.jpg';
import SamsungGalaxyS22PhoneCases_img4 from '../../images/blogs/Samsung_Galaxy_S22_Phone_Cases/4 Mobile Spigen.jpg';
import SamsungGalaxyS22PhoneCases_img5 from '../../images/blogs/Samsung_Galaxy_S22_Phone_Cases/5 Mobile Casetify.jpg';
import SamsungGalaxyS22PhoneCases_img6 from '../../images/blogs/Samsung_Galaxy_S22_Phone_Cases/6 Mobile Burga.jpg';
import SamsungGalaxyS22ScreenProtectorDesk_img1 from '../../images/blogs/Samsung_Galaxy_S22_Screen_Protector/1 Desktop ESR Gear.jpg';
import SamsungGalaxyS22ScreenProtector_img1 from '../../images/blogs/Samsung_Galaxy_S22_Screen_Protector/1 Mobile ESR Gear.jpg';
import SamsungGalaxyS22ScreenProtector_img2 from '../../images/blogs/Samsung_Galaxy_S22_Screen_Protector/2 Mobile Spigen.jpg';
import SamsungGalaxyS22ScreenProtector_img3 from '../../images/blogs/Samsung_Galaxy_S22_Screen_Protector/3 Mobile Otterbox.jpg';
import SamsungGalaxyS22ScreenProtector_img4 from '../../images/blogs/Samsung_Galaxy_S22_Screen_Protector/4 Mobile Whitestone Dome.jpg';
import SamsungGalaxyS22ScreenProtector_img5 from '../../images/blogs/Samsung_Galaxy_S22_Screen_Protector/5 Mobile UAG.jpg';

import AdVertical from '../../images/AdVertical.png';
import AdHorizontal from '../../images/AdHorizontal.png';
import AdMobile from '../../images/AdMobile.png';
import iPhone13AdImageMobile from '../../images/iPhone13AdImageMobile.png';
import iPhone13AdImageDesktop from '../../images/iPhone13AdImageDesktop.png';
import iPhone13AdImageHorizontal from '../../images/iPhone13AdImageHorizontal.png';
import HorizontalAdBackground from '../../images/HorizontalAdBackground.png';
import VerticalAdBackground from '../../images/VerticalAdBackground.png';
import MobileAdBackground from '../../images/MobileAdBackground.png';
import AsurionLogo from '../../images/mobile+logo.png';
import ArticleWristy from '../../images/blog-wristy-business.png';
import ArticleGoogle from '../../images/blog-master-google.png';
import ArticleTrick from '../../images/blog-trick-out.png';
import ArticleDuck from '../../images/blog-duckduckgo.png';

import Banner_image_for_iphone_13_cases from '../../images/Banner_image_for_iphone_13_cases.png';
import Apple_iPhone_13_Phone_cases from '../../images/Apple_iPhone_13_Phone_cases.png';

import Banner_image_for_iphone_13_accessory from '../../images/Banner_image_for_iphone_13_accessory.png';
import Apple_iPhone_13_Phone_Accessories from '../../images/Apple_iPhone_13_Phone_Accessories.png';

import Banner_image_for_iphone_13_screen_protector from '../../images/Banner_image_for_iphone_13_screen_protector.png';
import Apple_iPhone_13_Screen_Protector from '../../images/Apple_iPhone_13_Screen_Protector.png';

import Banner_image_for_iphone_SE_accessory from '../../images/Banner_image_for_iphone_SE_accessory.png';
import Apple_iPhone_SE_Phone_accessories from '../../images/Apple_iPhone_SE_Phone_accessories.png';

import Banner_image_for_iphoneSE_cases from '../../images/Banner_image_for_iphoneSE_cases.png';
import Apple_iPhone_SE_cases from '../../images/Apple_iPhone_SE_cases.png';

import Banner_image_for_iphoneSE_screen_protector from '../../images/Banner_image_for_iphoneSE_screen_protector.png';
import Apple_iPhone_SE_Screen_protector from '../../images/Apple_iPhone_SE_Screen_protector.png';

import Banner_image_for_samsung_s22_accessory from '../../images/Banner_image_for_samsung_s22_accessory.png';
import Samsung_galaxy_s22_accessories from '../../images/Samsung_galaxy_s22_accessories.png';

import Banner_image_for_Samsung_s22_cases from '../../images/Banner_image_for_Samsung_s22_cases.png';
import Samsung_galaxy_s22_phone_cases from '../../images/Samsung_galaxy_s22_phone_cases.png';

import Banner_image_for_samsung_s22_screen_protector from '../../images/Banner_image_for_samsung_s22_screen_protector.png';
import Samsung_galaxy_s22_screen_protectors from '../../images/Samsung_galaxy_s22_screen_protectors.png';

export const articles2 = {
    articles: [
        //1
        {
            associatedDevice: 'iphone-13',
            brand: 'Apple',
            title: 'iPhone 13 phone cases: 8 popular brands for your protection needs',
            slug: 'iphone-13-phone-cases-8-popular-brands-for-your-protection-needs',
            author: 'Asurion Mobile+ Team',
            publishDate: '20 April 2022',
            description:
                'Check out these trusted brands for iPhone 13 phone cases that range from rugged to sleek to everything in between.',
            bannerImage: Banner_image_for_iphone_13_cases,
            articleImage: Apple_iPhone_13_Phone_cases,
            bannerImageSourceText: 'Image source: Casetify',
            bannerBackgroundGradient: 'linear-gradient(118.74deg, #1A1A1A 10.34%, #3F513E 100%)',
            introText:
                'Looking for a phone case for your iPhone 13, iPhone 13 mini, iPhone 13 Pro, or iPhone 13 Pro Max (phew, that’s a mouthful!)? <br><br>Are you accident-prone and need a tough and rugged case to handle the mishaps and protect your precious iPhone 13? Or are you looking for a quirky case that shows off your personality that also offers some drop and scratch protection?<br><br>To help you on your quest to find the perfect iPhone 13 phone case, we’ve shortlisted 8 popular and highly rated phone case brands.',
            content: [
                {
                    header: '1 / UAG',
                    title: '<b>1 / Urban Armor Gear (UAG)</b>: Unapologetically rugged',
                    image: AppleiPhone13PhoneCases_img1,
                    imageSource: 'Image Source: UAG',
                    description:
                        'The iconic Urban Armor Gear (UAG) Monarch series is one of the most rugged-looking phone cases on the market. This “all-terrain” case comes with multiple layers of protection: a “shock-resistant core”, the armour frame, a layer of leather or carbon fibre, a shear plate made from polycarbonate, and “alloy metal hardware” that is surrounded by “impact-resistant rubber”.<br><br>Need more proof of its toughness? UAG Monarch cases meet the Military Drop Test Standard for extra shock protection and comes with a 10-year warranty.<br><br>If you’re looking for something slimmer or more discreet that does not skimp on the protection, check out other series like the Pathfinder series, the Civilian series, or Essential Armor series. Some of these products offer MagSafe compatibility and antimicrobial properties—features that the Monarch series doesn’t come with.',
                    moreInfoTitle: 'Get more information here',
                    moreInfoData: [
                        {
                            heading: 'Available for',
                            infoText: 'iPhone 13, iPhone 13 mini, iPhone 13 Pro, iPhone 13 Pro Max',
                        },
                        {
                            heading: 'Buy it from',
                            infoText:
                                "<a href='https://www.urbanarmorgear.com/'>UAG official website</a> or at leading retailers",
                        },
                    ],
                    proTip: '',
                },
                {
                    header: '2 / Supcase',
                    title: '<b>2 / Supcase</b>: Protection in high places',
                    image: AppleiPhone13PhoneCases_img2,
                    imageSource: 'Image Source: Supcase',
                    description:
                        'The Supcase Unicorn Beetle (UB) PRO has won awards from CNET for surviving the highest drop tests and have been tested for falls ranging from 20 ft (6m) to 50 ft (15m). Not only that, Supcase also boasts that the iconic case has stood strong against bullets, blades, getting run over, and more.<br><br>The case, unsurprisingly, has a rugged aesthetic, and is made of polycarbonate and TPU for shock absorption. For full device coverage, each UB PRO comes with a back cover, a front cover, and a holster with a belt clip that’s handy for smaller pockets. Each case also comes with a built-in kick stand for hands-free phone use.<br><br>For a sleeker case, consider other Supcase options like the UB Style or the UB Exo. iPhone 13 Pro and iPhone 13 Pro Max users can also consider the UB MAG series that’s MagSafe compatible.',
                    moreInfoTitle: 'Get more information here',
                    moreInfoData: [
                        {
                            heading: 'Available for',
                            infoText: 'iPhone 13, iPhone 13 mini, iPhone 13 Pro, iPhone 13 Pro Max',
                        },
                        {
                            heading: 'Buy it from',
                            infoText: 'Amazon',
                        },
                    ],

                    proTip: '',
                },
                {
                    header: '3 / Otterbox',
                    title: '<b>3 / Otterbox</b>: Tough and pretty',
                    image: AppleiPhone13PhoneCases_img3,
                    imageSource: 'Image Source: Otterbox',
                    description:
                        'Perhaps you don’t need a case that screams “I’m rugged and I like trekking the outback”. But you would like the protection that comes with a rugged case. Otterbox is a good middle ground, with its myriad of protective-yet-sleek phone cases that come in various colours and patterns.<br><br>Take its Commuter series that comes in fun colours like teal, pink, and blue and has a slim and pocket-friendly design while offering drop protection that surpasses the military standard (MIL-STD-810G 516.6).<br><br>Or consider the Symmetry (Clear) series that come with floral, fruity patterns and a sleek body that does not skimp on the protection: it’s tested for 3X as many drops as military standard and comes with antimicrobial technology. The slightly thinner and non-metal form factor of Otterbox cases also means that many of them are compatible with MagSafe accessories and wireless charging.',
                    moreInfoTitle: 'Get more information here',
                    moreInfoData: [
                        {
                            heading: 'Available for',
                            infoText: 'iPhone 13, iPhone 13 mini, iPhone 13 Pro, iPhone 13 Pro Max',
                        },
                        {
                            heading: 'Buy it from',
                            infoText:
                                "<a href='http://otterbox.com.au/'>Otterbox official website</a> or at leading retailers",
                        },
                    ],

                    proTip: `<b>Protection Pro-Tip</b><br><br>Looking for that extra level of protection? Asurion Mobile+ insurance has got you covered. All our plans come with screen damage protection so you won’t have to worry about damaged screens ruining your day. Pick your perfect plan to go with your perfect phone case.`,
                },
                {
                    header: '4 / Speck',
                    title: '<b>4 / Speck</b>: Simple protection, stylish design',
                    image: AppleiPhone13PhoneCases_img4,
                    imageSource: 'Image Source: Otterbox',
                    description:
                        'You won’t find triple-layer, bullet-proof cases here that feature the tough-as-nails aesthetic. Instead, Speck cases offer simple, fuss-free protective cases with a clean and stylish design. You’ll also find MagSafe-compatible cases if that’s something you need.<br><br>Their Presideo Pro cases come with a soft-touch finish that’s comfortable in hand, and each case is lined with air capsules for drop protection, tested for heights up to 13 ft (4m). <br><br>For something grippier—perfect if you’ve got sweaty palms or butter fingers—look at the Presideo Grip, which comes with “no-slip grips” for a firmer hold on your iPhone 13. The Grip also comes with the proprietary air capsules design that act as an airbag for your device, as well as antimicrobial protection to reduce bacteria growth.',
                    moreInfoTitle: 'Get more information here',
                    moreInfoData: [
                        {
                            heading: 'Available for',
                            infoText: 'iPhone 13, iPhone 13 mini, iPhone 13 Pro, iPhone 13 Pro Max',
                        },
                        {
                            heading: 'Buy it from',
                            infoText: 'Amazon or at leading retailers',
                        },
                    ],
                    proTip: '',
                },
                {
                    header: '5 / Casetify',
                    title: '<b>5 / Casetify</b>: Flaunt your style',
                    image: AppleiPhone13PhoneCases_img5,
                    imageSource: 'Image Source: Casetify',
                    description:
                        'Casetify cases come in eye-catching designs and colours, and the iconic Casetify branding around its raised camera ring. You’ll find a wide range of prints and designs from various artists in its extensive catalogue.<br><br>Can’t find something that fits your unique style in its catalogue? Take customisation to the next level: you can choose your own layout, fonts, text, colours and prints or photos for your phone case. You’ll also find a myriad of fun effects like glitter and sand for a more interactive phone case. <br><br>Don’t worry, you won’t be shortchanged on the protection either. Depending on the series you select, cases come with protections ranging from bezel screen protection, 360-degree impact protection, anti-microbial coating, and more.',
                    moreInfoTitle: 'Get more information here',
                    moreInfoData: [
                        {
                            heading: 'Available for',
                            infoText: 'iPhone 13, iPhone 13 mini, iPhone 13 Pro, iPhone 13 Pro Max',
                        },
                        {
                            heading: 'Buy it from',
                            infoText: "<a href='https://www.casetify.com/en_AU/'>Casetify official website</a>",
                        },
                    ],

                    proTip: '<b>Protection Pro-Tip</b><br><br>You’ve got an eye for aesthetics if you’re interested in a Casetify phone case. If phone damage is not your vibe, Asurion Mobile+ insurance offers  replacements for damaged phones with our Classic and Prestige plans. Pair it with your Casetify case and you’ve got a match made in heaven.',
                },
                {
                    header: '6 / Smartish',
                    title: '<b>6 / Smartish</b>: Fan-favourite grippy case',
                    image: AppleiPhone13PhoneCases_img6,
                    imageSource: 'Image Source: Smartish',
                    description:
                        'The Gripmunk line of phone cases from Smartish come with a simple value proposition: slim, grippy, and protective—like the Speck, this case also cushions your device with air pockets.<br><br>Smartish cases are a fan favourite: they’ve been featured everywhere, from the New York Times’ Wirecutter series to Esquire to this very article you’re reading, and if reviews on Amazon and its official site are anything to go by, you wouldn’t go wrong slapping on one of Smartish’s cases.<br><br>While their pre-made design options aren’t as varied as Casetify’s, you’ll still find stylish prints done up by different artists as well as cases in different colours. The Gripmunk also comes in a MagSafe-compatible options, or if you’d like something with more protection, opt for the Gripzilla.',
                    moreInfoTitle: 'Get more information here',
                    moreInfoData: [
                        {
                            heading: 'Available for',
                            infoText: 'iPhone 13, iPhone 13 mini, iPhone 13 Pro, iPhone 13 Pro Max',
                        },
                        {
                            heading: 'Buy it from',
                            infoText: 'Amazon',
                        },
                    ],
                    proTip: '',
                },
                {
                    header: '7 / Bellroy',
                    title: '<b>7 / Bellroy</b>: Premium sophistication',
                    image: AppleiPhone13PhoneCases_img7,
                    imageSource: 'Image Source: Bellroy',
                    description:
                        'If you’re a fan of understated, sophisticated design, definitely take a look at Bellroy’s premium leather cases. Crafted right here in Australia, Bellroy’s cases are sleek and well-crafted—although you wouldn’t find the ultra-protective full-metal fixings from the more rugged options listed above.<br><br>Take the 3 Card Phone Case that manages to pack a card-holding compartment in its slim form factor. Adding to this premium build would be its soft microfiber lining and chamfered edges. The trapdoor to access your cards also doubles as a phone stand, a clever touch.<br><br>If you’re looking for a MagSafe-compatible option, check out the Mod Phone Case + Wallet line that keeps your cards snapped to the back of your phone case when you need them. Simply snap it off to use MagSafe charging.',
                    moreInfoTitle: 'Get more information here',
                    moreInfoData: [
                        {
                            heading: 'Warranty?',
                            infoText: 'Three (3) year warranty',
                        },
                        {
                            heading: 'Available for',
                            infoText: 'iPhone 13, iPhone 13 mini, iPhone 13 Pro, iPhone 13 Pro Max',
                        },
                        {
                            heading: 'Buy it from',
                            infoText:
                                "<a href='https://bellroy.com/'>Bellroy official website</a> or at leading retailers",
                        },
                    ],
                    proTip: '',
                },
                {
                    header: '8 / Apple',
                    title: '<b>8 / Apple</b>: Modular wallet from the manufacturer',
                    image: AppleiPhone13PhoneCases_img8,
                    imageSource: 'Image Source: Apple',
                    description:
                        'Another MagSafe leather wallet option would be from Apple itself. Mix and match your modular wallet with their existing cases and create colour combinations unique to your style. The wallet is also available in a range of colours such as Dark Cherry and Wisteria.<br><br>The wallet can hold up to three cards and it’s also shielded to protect your credit cards.',
                    moreInfoTitle: 'Get more information here',
                    moreInfoData: [
                        {
                            heading: 'Available for',
                            infoText: 'iPhone 13, iPhone 13 mini, iPhone 13 Pro, iPhone 13 Pro Max',
                        },
                        {
                            heading: 'Buy it from',
                            infoText:
                                "<a href='https://www.apple.com/shop/iphone/accessories'>iPhone Accessories official website</a> or at authorised retailers",
                        },
                    ],
                    proTip: '',
                },
            ],
            advertisement: {
                verticalAdImage: AdVertical,
                horizontalAdImage: AdHorizontal,
                mobileAdImage: AdMobile,
                adContent: {
                    asurionLogo: AsurionLogo,
                    line1Desktop: 'New iPhone 13 insurance',
                    line2Desktop: 'Premium starts from',
                    line1Mobile: 'the smart phone insurance.',
                    line2Mobile: '<b>iPhone 13</b> premium starts',
                    line3Mobile: 'from',
                    price: '$5.95',
                    paymentDurationText: '/mth',
                    phoneImageVertical: iPhone13AdImageDesktop,
                    phoneImageHotizontal: iPhone13AdImageHorizontal,
                    phoneImageMobile: iPhone13AdImageMobile,
                    buttonText: 'View phone plans',
                    buttonLink: '',
                    backgroundImageVertical: VerticalAdBackground,
                    backgroundImageHorizontal: HorizontalAdBackground,
                    backgroundImageMobile: MobileAdBackground,
                    legalText:
                        'Product issued by Liberty Mutual Insurance Company. Apple and iPhone are trademarks of Apple, Inc., registered in the U.S. and other countries and regions. Asurion is not affiliated with, or sponsored or endorsed by, Apple.',
                    legalTextMobile:
                        'Issued by Liberty Mutual Insurance Company AFSL 530842. T&Cs apply. Consider the PDS and TMD available online',
                },
            },
            mainKeyword: {
                name: 'iPhone 13',
                slug: 'iphone-13',
            },
            relatedKeywords: [
                {
                    name: 'Phone cases',
                    slug: 'phone-cases',
                },
            ],
            footerTitle: 'Apply for Asurion Mobile+ insurance for even more protection!',
            footerDescription:
                "A case can protect your phone from small drops but may not be able to shield your sleek new iPhone 13 from nastier accidents. For more peace of mind, Asurion Mobile+ <i>smart</i> phone insurance can cover your phone for accidental damage, loss, and theft.<br><br>Go to <a  data-cke-saved-href='www.asurion.com.au' href='https://www.asurion.com.au/' target='_blank'>www.asurion.com.au</a> to compare our plans and get extra protection for your phone today.",
            articlesYouMayLike: [
                {
                    title: 'How to Troubleshoot Someone Else’s Phone Remotely',
                    slug: '#',
                },
                {
                    title: '22 Great Wireless Chargers to Refuel Your Phone (or Watch)',
                    slug: '#',
                },
                {
                    title: 'Power Up Anywhere with Our Favorite Portable Chargers',
                    slug: '#',
                },
            ],
            keywords: [
                {
                    name: 'PROTECTION',
                    slug: '#',
                },
                {
                    name: 'ACCESSORIES',
                    slug: '#',
                },
                {
                    name: 'IPHONE',
                    slug: '#',
                },
                {
                    name: 'CASES',
                    slug: '#',
                },
                {
                    name: 'SHOPPING',
                    slug: '#',
                },
                {
                    name: 'HOW-TO',
                    slug: '#',
                },
            ],
            moreArticlesText: 'More phone accessories advice',
            moreArticles: [
                {
                    title: 'Wearables reduce your reliance on a phone while keeping you connected',
                    slug: '#',
                    image: ArticleWristy,
                    keyword: 'WRIST-Y BUSINESS',
                },
                {
                    title: "How to Master Google's AI Phone Call Features",
                    slug: '#',
                    image: ArticleGoogle,
                    keyword: 'HOW-TO',
                },
                {
                    title: 'DuckDuckGo Wants to Stop Apps From Tracking You on Android',
                    slug: '#',
                    image: ArticleDuck,
                    keyword: 'PRIVACY',
                },
                {
                    title: 'Trick Out Your iPhone12 with These Cases and Accessories',
                    slug: '#',
                    image: ArticleTrick,
                    keyword: 'GIFT GUIDE',
                },
            ],
        },
        //2
        {
            associatedDevice: 'iphone-13',
            brand: 'Apple',
            title: 'Useful phone accessories to power up your iPhone 13',
            slug: 'useful-phone-accessories-to-power-up-your-iphone-13',
            author: 'Asurion Mobile+ Team',
            publishDate: '20 April 2022',
            description:
                'Maximise your iPhone 13’s utility with these accessories, from mounts to charging pads to wallets.',
            bannerImage: Banner_image_for_iphone_13_accessory,
            articleImage: Apple_iPhone_13_Phone_Accessories,
            bannerImageSourceText: 'Image source: Belkin',
            bannerBackgroundGradient: 'linear-gradient(118.74deg, #1A1A1A 10.34%, #3F513E 100%)',
            introText:
                'Apple’s iPhone 13 series is a powerhouse straight out of the box, but you can get even more out of your device with accessories, from MagSafe-compatible mounts to professional film-making kits. Let’s check them out.',
            content: [
                {
                    header: '1 / Apple Magsafe battery pack',
                    title: '<b>1 / Apple MagSafe battery pack</b>: More Apple juice',
                    image: AppleiPhone13Accessoriesmob_img1,
                    imageSource: 'Image source: Apple',
                    description:
                        'Apple’s official MagSafe battery pack will wirelessly charge your iPhone 13 when you’re out and about with no access to a wall charger or charging pad. The official website states that the battery pack will provide an additional 40-70% charge depending on your phone model.<br/><br/>It’s made by Apple, so you can expect seamless integration with your iPhone 13—for example, it automatically charges your phone once you snap it on, and your iPhone’s battery widget will display relevant information such as how much charge is left in your pack.',
                    moreInfoTitle: 'Get more information here',
                    moreInfoData: [
                        {
                            heading: 'Compatible models',
                            infoText: 'iPhone 13, iPhone 13 mini, iPhone 13 Pro, iPhone 13 Pro Max',
                        },
                        {
                            heading: 'Available at',
                            infoText: 'Official Apple website and authorised retailers',
                        },
                    ],
                    proTip: '',
                },
                {
                    header: '2 / Apple MagSafe wallet',
                    title: '<b>2/ Apple MagSafe leather wallet</b>: Keep your cards close',
                    image: AppleiPhone13Accessoriesmob_img2,
                    imageSource: 'Image Source: Apple',
                    description:
                        'Another official Apple product, the MagSafe-compatible wallet is a stylish addition to your iPhone 13. Simply snap the wallet onto your device or your MagSafe case and slide up to 3 cards into it; who needs a separate wallet anymore?<br/><br/>An additional perk of being an Apple product, your MagSafe wallet also supports Find My, so you’ll be notified of your wallet’s last-known location if it gets separated from your phone. Last but not least, the MagSafe wallet looks sleek, made of finished leather with sophisticated colour options such as Wisteria, Midnight, and Sequoia Green.',
                    moreInfoTitle: 'Get more information here',
                    moreInfoData: [
                        {
                            heading: 'Compatible models',
                            infoText: 'iPhone 13, iPhone 13 mini, iPhone 13 Pro, iPhone 13 Pro Max',
                        },
                        {
                            heading: 'Available at',
                            infoText: 'Official Apple website and authorised retailers',
                        },
                    ],

                    proTip: '',
                },
                {
                    header: '3 / Mophie 3-in-1 stand charger',
                    title: '<b>3 / mophie 3-in-1 stand charger</b>: One accessory to charge them all',
                    image: AppleiPhone13Accessoriesmob_img3,
                    imageSource: 'Image Source: Apple',
                    description:
                        'The mophie 3-in-1 Stand for MagSafe Charger is great for those who have more than one Apple product—you can charge your iPhone 13, AirPods Pro, and Apple Watch at the same time on this bad boy. The stand has separate spots specifically designed to charge the different products, and the magnetic components are aligned so that your iPhone 13 is accurately placed for more efficient charging.<br/><br/>Looking for something with a slimmer form factor? Consider the mophie 3-in-1 MagSafe-compatible travel charger, which comes in a sleek carrying case that unrolls into the charging pad.',
                    moreInfoTitle: 'Get more information here',
                    moreInfoData: [
                        {
                            heading: 'Compatible models',
                            infoText: 'iPhone 13, iPhone 13 mini, iPhone 13 Pro, iPhone 13 Pro Max',
                        },
                        {
                            heading: 'Available at',
                            infoText: 'Official Apple website and authorised retailers',
                        },
                    ],

                    proTip: '',
                },
                {
                    header: '4 / Belkin wireless car charger',
                    title: '<b>4 / Belkin magnetic wireless car charger</b>: Souped up car mount',
                    image: AppleiPhone13Accessoriesmob_img4,
                    imageSource: 'Image Source: Belkin',
                    description:
                        'This Belkin car vent mount doubles as a 10W MagSafe charger, so you can keep your iPhone 13 powered up while navigating to your destination. Its powerful magnetic module keeps your phone stably mounted so your iPhone 13 stays safe on bumpy rides; it works with MagSafe cases too, so you don’t have to worry about popping your phone in and out of its case. <br><br>Another feature: the mount is rotatable so you can use it in landscape or portrait according to your needs. The Belkin site also boasts that the mount allows for one-handed placement, a “seamless set-and-drive experience”.',
                    moreInfoTitle: 'Get more information here',
                    moreInfoData: [
                        {
                            heading: 'Compatible models',
                            infoText: 'iPhone 13, iPhone 13 mini, iPhone 13 Pro, iPhone 13 Pro Max',
                        },
                        {
                            heading: 'Available at',
                            infoText: 'Official Belkin site, Amazon',
                        },
                    ],
                    proTip: '',
                },
                {
                    header: '5 / Peak Design bike mount',
                    title: '<b>5 / Peak Design bike mount</b>: For cyclists, by cyclists',
                    image: AppleiPhone13Accessoriesmob_img5,
                    imageSource: 'Image Source: Peak Design',
                    description:
                        'Designed by avid cyclists, the Peak Design Out Front Bike Mount installs onto your bike handle, is built from solid machined aluminum, and is weatherproof, lightweight, and durable for those who love riding rugged trails—or enjoying a breezy weekend ride in the city.<br><br>The mount may look sleek and unassuming, but it is feature packed. Its ultra-secure magnetic locking technology allows for one-handed attachment and removal but is still secure enough to keep your iPhone 13 safe on gravel trails. Mounting options abound as you can choose from portrait or landscape mode or mount an accessory such as a bike light. Its flip-to-film feature allows you to instantly transform your phone into a POV camera.',
                    moreInfoTitle: 'Get more information here',
                    moreInfoData: [
                        {
                            heading: 'Compatible models',
                            infoText: 'iPhone 13, iPhone 13 mini, iPhone 13 Pro, iPhone 13 Pro Max',
                        },
                        {
                            heading: 'Available at',
                            infoText: 'Official Peak Design website',
                        },
                    ],

                    proTip: '',
                },
                {
                    header: '6 / Moment creative gear',
                    title: '<b>6 / Moment creative gear</b>: Creator’s toolkit',
                    image: AppleiPhone13Accessoriesmob_img6,
                    imageSource: 'Image Source: Moment',
                    description:
                        'Whether you’re an aspiring TikTok creator or want your Zoom video and sound to be picture perfect and crystal clear, Moment has the lens, tripod or microphone for you. With a Moment phone case and a compatible lens mount, you can tap into iPhone 13’s superb camera and turn your device into a truly pro set up. Choose from a myriad of lenses such as tele, wide, macro, fisheye… and more.<br><br>Not sure where to start? Moment has starter kits that bundle the essentials into one easy-to-buy set, so you don’t have to spend hours scouring their extensive catalogue shortlisting potential candidates.',
                    moreInfoTitle: 'Get more information here',
                    moreInfoData: [
                        {
                            heading: 'Compatible models',
                            infoText: 'iPhone 13, iPhone 13 mini, iPhone 13 Pro, iPhone 13 Pro Max',
                        },
                        {
                            heading: 'Available at',
                            infoText: 'Official Moments website',
                        },
                    ],
                    proTip: '',
                },
            ],
            advertisement: {
                verticalAdImage: AdVertical,
                horizontalAdImage: AdHorizontal,
                mobileAdImage: AdMobile,
                adContent: {
                    asurionLogo: AsurionLogo,
                    line1Desktop: 'New iPhone 13 insurance',
                    line2Desktop: 'Premium starts from',
                    line1Mobile: 'the smart phone insurance.',
                    line2Mobile: '<b>iPhone 13</b> premium starts',
                    line3Mobile: 'from',
                    price: '$5.95',
                    paymentDurationText: '/mth',
                    phoneImageVertical: iPhone13AdImageDesktop,
                    phoneImageHotizontal: iPhone13AdImageHorizontal,
                    phoneImageMobile: iPhone13AdImageMobile,
                    buttonText: 'View phone plans',
                    buttonLink: '',
                    backgroundImageVertical: VerticalAdBackground,
                    backgroundImageHorizontal: HorizontalAdBackground,
                    backgroundImageMobile: MobileAdBackground,
                    legalText:
                        'Product issued by Liberty Mutual Insurance Company. Apple and iPhone are trademarks of Apple, Inc., registered in the U.S. and other countries and regions. Asurion is not affiliated with, or sponsored or endorsed by, Apple.',
                    legalTextMobile:
                        'Issued by Liberty Mutual Insurance Company AFSL 530842. T&Cs apply. Consider the PDS and TMD available online',
                },
            },
            mainKeyword: {
                name: 'iPhone 13',
                slug: 'iphone-13',
            },
            relatedKeywords: [
                {
                    name: 'Phone accessories',
                    slug: 'phone-accessories',
                },
            ],
            footerTitle: 'Apply for Asurion Mobile+ insurance for even more protection!',
            footerDescription:
                "A case can protect your phone from small drops but may not be able to shield your sleek new iPhone 13 from nastier accidents. For more peace of mind, Asurion Mobile+ <i>smart</i> phone insurance can cover your phone for accidental damage, loss, and theft.<br><br>Go to <a  data-cke-saved-href='www.asurion.com.au' href='https://www.asurion.com.au/' target='_blank'>www.asurion.com.au</a> to compare our plans and get extra protection for your phone today.",
            articlesYouMayLike: [
                {
                    title: 'How to Troubleshoot Someone Else’s Phone Remotely',
                    slug: '#',
                },
                {
                    title: '22 Great Wireless Chargers to Refuel Your Phone (or Watch)',
                    slug: '#',
                },
                {
                    title: 'Power Up Anywhere with Our Favorite Portable Chargers',
                    slug: '#',
                },
            ],
            keywords: [
                {
                    name: 'PROTECTION',
                    slug: '#',
                },
                {
                    name: 'ACCESSORIES',
                    slug: '#',
                },
                {
                    name: 'IPHONE',
                    slug: '#',
                },
                {
                    name: 'CASES',
                    slug: '#',
                },
                {
                    name: 'SHOPPING',
                    slug: '#',
                },
                {
                    name: 'HOW-TO',
                    slug: '#',
                },
            ],
            moreArticlesText: 'More phone accessories advice',
            moreArticles: [
                {
                    title: 'Wearables reduce your reliance on a phone while keeping you connected',
                    slug: '#',
                    image: ArticleWristy,
                    keyword: 'WRIST-Y BUSINESS',
                },
                {
                    title: "How to Master Google's AI Phone Call Features",
                    slug: '#',
                    image: ArticleGoogle,
                    keyword: 'HOW-TO',
                },
                {
                    title: 'DuckDuckGo Wants to Stop Apps From Tracking You on Android',
                    slug: '#',
                    image: ArticleDuck,
                    keyword: 'PRIVACY',
                },
                {
                    title: 'Trick Out Your iPhone12 with These Cases and Accessories',
                    slug: '#',
                    image: ArticleTrick,
                    keyword: 'GIFT GUIDE',
                },
            ],
        },
        //3
        {
            associatedDevice: 'iphone-13',
            brand: 'Apple',
            title: 'Screen Protectors to Keep Your iPhone 13 Pristine',
            slug: 'screen-protectors-to-keep-your-iphone-13-pristine',
            author: 'Asurion Mobile+ Team',
            publishDate: '20 April 2022',
            description:
                'Keep your phone looking brand new and scratch free without sacrificing functionality with a screen protector. Here are 6 options to check out.',
            bannerImage: Banner_image_for_iphone_13_screen_protector,
            articleImage: Apple_iPhone_13_Screen_Protector,
            bannerImageSourceText: 'Image source: Belkin',
            bannerBackgroundGradient: 'linear-gradient(118.74deg, #1A1A1A 10.34%, #3F513E 100%)',
            introText:
                'Love how pristine your iPhone 13 looks right now? Unfortunately, it is unlikely to stay that way without a screen protector. While Apple has introduced the Ceramic Shield to increase the strength of your phone and prevent it from shattering, it does not seem to have made the screen more scratch resistant.<br><br>Thankfully, it is easy to keep your phone looking brand new and scratch free without sacrificing functionality with a screen protector.',
            content: [
                {
                    header: '1 / Supershieldz',
                    title: '<b>1 / Supershieldz</b>: Affordable, no-frills protection',
                    image: AppleiPhone13ScreenProtector_img1,
                    imageSource: 'Image source: Supershieldz',
                    description:
                        'A no-frills option, the Supershieldz screen protector comes with three pieces of hydrophobic and oleophobic coated tempered glass with 9H-hardness to protect your iPhone 13 and keep your screen smudge-resistant.<br><br>As one of most affordable options available in the market, each package only comes with the essentials to complete your installation and does not include any alignment tools.',
                    moreInfoTitle: 'Get more information here',
                    moreInfoData: [
                        {
                            heading: 'Available for',
                            infoText: 'iPhone 13 mini, iPhone 13, iPhone 13 Pro, iPhone 13 Pro Max',
                        },
                        {
                            heading: 'Buy it from',
                            infoText:
                                '<a href="https://www.amazon.com.au/Supershieldz-Designed-Tempered-Protector-Scratch/dp/B09D9YBDZD/">Amazon</a>',
                        },
                    ],
                    proTip: '',
                },
                {
                    header: '2 / Trianium',
                    title: '<b>2 / Trianium Tempered Glass</b>: Affordable alignment aid',
                    image: AppleiPhone13ScreenProtector_img2,
                    imageSource: 'Image Source: Trianum',
                    description:
                        'Trianium aims to provide protection for your phone and a good installation experience without having to break the bank. The tempered glass is rated at a standard 9H hardness to withstand light scratches and falls, and is coated with a hydrophobic and oleophobic coating to resist water and fingerprints.<br><br>Each pack comes with dry and wet wipes and an alignment tool, which should make self-application quick and easy—great for those with poorer fine motor skills or shaky hands.',
                    moreInfoTitle: 'Get more information here',
                    moreInfoData: [
                        {
                            heading: 'Available for',
                            infoText: 'iPhone 13, iPhone 13 Pro',
                        },
                        {
                            heading: 'Buy it from',
                            infoText:
                                '<a href="https://www.amazon.com.au/Trianium-Screen-Protector-Compatible-Tempered/dp/B09D8WY8B8/">Amazon</a>',
                        },
                    ],

                    proTip: '',
                },
                {
                    header: '3 / QHOHQ',
                    title: '<b>3 / QHOHQ Tempered Glass and Camera Lens Protector</b>: All-in-One',
                    image: AppleiPhone13ScreenProtector_img3,
                    imageSource: 'Image Source: Amazon',
                    description:
                        'The QHOHQ tempered glass is a value-for-money screen protector: in addition to three pieces of 9H hardness tempered glass protectors, each package also contains 3 camera lens protectors to protect your precious iPhone 13 camera module.<br><br>However, do note that you will only be provided with basic accessories such as dry wipes, wet wipes, and anti-dust stickers, and application without an alignment tool can be more challenging to get it perfect right on your first try.',
                    moreInfoTitle: 'Get more information here',
                    moreInfoData: [
                        {
                            heading: 'Available for',
                            infoText: 'iPhone 13, iPhone 13 Pro, iPhone 13 Pro Max',
                        },
                        {
                            heading: 'Buy it from',
                            infoText:
                                '<a href="https://www.amazon.com.au/QHOHQ-Protector-iPhone-13-6-1/dp/B09BZRM3RV">Amazon</a>',
                        },
                    ],
                    proTip: '',
                },
                {
                    header: '4 / Spigen EZ Fit GLAS.tR Slim',
                    title: '<b>4 / Spigen EZ Fit GLAS.tR Slim</b>: Protection from a trusted brand',
                    image: AppleiPhone13ScreenProtector_img4,
                    imageSource: 'Image Source: Spigen',
                    description:
                        'Spigen is a well-known maker of protective phone cases and accessories, and it is no surprise that they have their own line of screen protectors. The EZ Fit GLAS.tR Slim comes with two pieces of 9H hardness tempered glass protectors, coated with an oleophobic coating to prevent fingerprint smudges.<br><br>The inclusion of a user-friendly auto-alignment installation tray is a nice touch and makes the installation process extremely easy for anyone to perform at home.<br><br>If you have sensitive documents on your phone, Spigen also has a privacy focused version of the EZ Fit GLAS on their official site which makes the screen visible only to the someone directly in front of the phone.',
                    moreInfoTitle: 'Get more information here',
                    moreInfoData: [
                        {
                            heading: 'Available for',
                            infoText: 'iPhone 13, iPhone 13 Pro',
                        },
                        {
                            heading: 'Buy it from',
                            infoText: '<a href="https://www.spigenstore.com.au/">Spigen official site</a>',
                        },
                    ],
                    proTip: '',
                },
                {
                    header: '5 / Belkin ScreenForce',
                    title: '<b>5 / Belkin ScreenForce TemperedGlass</b>: Premium protector',
                    image: AppleiPhone13ScreenProtector_img5,
                    imageSource: 'Image Source: Peak Design',
                    description:
                        'A well-known brand in the phone accessories space, Belkin and their ScreenForce line of tempered glass is a good choice for anyone who wishes to have peace of mind knowing that their prized iPhone is well protected and will stay pristine.<br><br>Each package comes with a premium chemically-engineered Japanese tempered glass with advanced impact protection, and the glass is treated with an antimicrobial agent to protect the screen protector from microbial growth. An Easy Align tray is also included for simple, precise, bubble-free application that you can perform on your own.',
                    moreInfoTitle: 'Get more information here',
                    moreInfoData: [
                        {
                            heading: 'Available for',
                            infoText: 'iPhone 13 mini, iPhone 13, iPhone 13 Pro, iPhone 13 Pro Max',
                        },
                        {
                            heading: 'Buy it from',
                            infoText:
                                '<a href="https://www.belkin.com/au/screen-protectors/iphone-protect/screenforce-temperedglass-treated-screen-protector-for-iphone/p/p-ova020/">Belkin official site</a>, Amazon',
                        },
                    ],

                    proTip: `<b>Protection Pro-Tip</b><br><br>You’ve decided on a premium tempered glass screen protector to protect your premium iPhone 13, but is that really enough? In the event of spills and drops, Asurion Mobile+ will have you covered. Depending on the plan you pick, be covered for screen repair, damage, and loss/theft replacement. <a href='${process.env.GATSBY_MOBILEPLUS_PLANS_URL}'>Find out more about our plans</a>`,
                },
                {
                    header: '6 / Belkin UltraGlass',
                    title: '<b>6 / Belkin UltraGlass</b>: Exclusive to Apple',
                    image: AppleiPhone13ScreenProtector_img6,
                    imageSource: 'Image Source: Apple',
                    description:
                        'An Apple exclusive, the Belkin UltraGlass is your pick if you are looking for a premium option or frequently use the phone in challenging conditions. It sets itself apart by being made of Lithium Aluminosilicate (LAS), which provides increased strength and scratch resistance and allows it to survive drops from twice the height of conventional tempered glass. <br><br>The Belkin UltraGlass comes with the basic accessories (cleaning cloth, dust removal sticker), and an Easy Align tray that makes precise application at home quick and easy.',
                    moreInfoTitle: 'Get more information here',
                    moreInfoData: [
                        {
                            heading: 'Available for',
                            infoText: 'iPhone 13 mini, iPhone 13, iPhone 13 Pro, iPhone 13 Pro Max',
                        },
                        {
                            heading: 'Buy it from',
                            infoText: '<a href="https://www.apple.com/au/store" >Apple store</a>',
                        },
                    ],
                    proTip: '',
                },
            ],
            advertisement: {
                verticalAdImage: AdVertical,
                horizontalAdImage: AdHorizontal,
                mobileAdImage: AdMobile,
                adContent: {
                    asurionLogo: AsurionLogo,
                    line1Desktop: 'New iPhone 13 insurance',
                    line2Desktop: 'Premium starts from',
                    line1Mobile: 'the smart phone insurance.',
                    line2Mobile: '<b>iPhone 13</b> premium starts',
                    line3Mobile: 'from',
                    price: '$5.95',
                    paymentDurationText: '/mth',
                    phoneImageVertical: iPhone13AdImageDesktop,
                    phoneImageHotizontal: iPhone13AdImageHorizontal,
                    phoneImageMobile: iPhone13AdImageMobile,
                    buttonText: 'View phone plans',
                    buttonLink: '',
                    backgroundImageVertical: VerticalAdBackground,
                    backgroundImageHorizontal: HorizontalAdBackground,
                    backgroundImageMobile: MobileAdBackground,
                    legalText:
                        'Product issued by Liberty Mutual Insurance Company. Apple and iPhone are trademarks of Apple, Inc., registered in the U.S. and other countries and regions. Asurion is not affiliated with, or sponsored or endorsed by, Apple.',
                    legalTextMobile:
                        'Issued by Liberty Mutual Insurance Company AFSL 530842. T&Cs apply. Consider the PDS and TMD available online',
                },
            },
            mainKeyword: {
                name: 'iPhone 13',
                slug: 'iphone-13',
            },
            relatedKeywords: [
                {
                    name: 'Phone screen protectors',
                    slug: 'phone-screen-protectors',
                },
            ],
            footerTitle: 'Apply for Asurion Mobile+ insurance for even more protection!',
            footerDescription:
                "A case can protect your phone from small drops but may not be able to shield your sleek new iPhone 13 from nastier accidents. For more peace of mind, Asurion Mobile+ <i>smart</i> phone insurance can cover your phone for accidental damage, loss, and theft.<br><br>Go to <a  data-cke-saved-href='www.asurion.com.au' href='https://www.asurion.com.au/' target='_blank'>www.asurion.com.au</a> to compare our plans and get extra protection for your phone today.",
            articlesYouMayLike: [
                {
                    title: 'How to Troubleshoot Someone Else’s Phone Remotely',
                    slug: '#',
                },
                {
                    title: '22 Great Wireless Chargers to Refuel Your Phone (or Watch)',
                    slug: '#',
                },
                {
                    title: 'Power Up Anywhere with Our Favorite Portable Chargers',
                    slug: '#',
                },
            ],
            keywords: [
                {
                    name: 'PROTECTION',
                    slug: '#',
                },
                {
                    name: 'ACCESSORIES',
                    slug: '#',
                },
                {
                    name: 'IPHONE',
                    slug: '#',
                },
                {
                    name: 'CASES',
                    slug: '#',
                },
                {
                    name: 'SHOPPING',
                    slug: '#',
                },
                {
                    name: 'HOW-TO',
                    slug: '#',
                },
            ],
            moreArticlesText: 'More phone accessories advice',
            moreArticles: [
                {
                    title: 'Wearables reduce your reliance on a phone while keeping you connected',
                    slug: '#',
                    image: ArticleWristy,
                    keyword: 'WRIST-Y BUSINESS',
                },
                {
                    title: "How to Master Google's AI Phone Call Features",
                    slug: '#',
                    image: ArticleGoogle,
                    keyword: 'HOW-TO',
                },
                {
                    title: 'DuckDuckGo Wants to Stop Apps From Tracking You on Android',
                    slug: '#',
                    image: ArticleDuck,
                    keyword: 'PRIVACY',
                },
                {
                    title: 'Trick Out Your iPhone12 with These Cases and Accessories',
                    slug: '#',
                    image: ArticleTrick,
                    keyword: 'GIFT GUIDE',
                },
            ],
        },
        //4
        {
            associatedDevice: 'iphone-se-3rd-generation',
            brand: 'Apple',
            title: 'Phone Accessories to Level Up Your iPhone SE (2022)',
            slug: 'phone-accessories-to-level-up-your-iphone-se-2022',
            author: 'Asurion Mobile+ Team',
            publishDate: '20 April 2022',
            description: 'Phone accessories to make the most out of your iPhone SE (2022)',
            bannerImage: Banner_image_for_iphone_SE_accessory,
            articleImage: Apple_iPhone_SE_Phone_accessories,
            bannerImageSourceText: 'Image source: Apple',
            bannerBackgroundGradient: 'linear-gradient(118.74deg, #1C1C1C 10.34%, #6B6B6C 100%)',
            introText:
                'The iPhone SE (2022) is Apple’s affordable phone that’s known to be small but mighty. If you’re looking to level up your pocket-friendly iPhone SE (2022), here are some phone accessories you can pair your device with. <br><br>Let’s check them out.',
            content: [
                {
                    header: '1 / Scosche MagicGrip',
                    title: '1 / Scosche MagicGrip Freeflow Wireless Charging Vent Mount',
                    image: AppleiPhoneSE2022Accessories_img1,
                    imageSource:
                        'Image source: <a href="https://store.storeimages.cdn-apple.com/8756/as-images.apple.com/is/HNGW2?wid=1144&hei=1144&fmt=jpeg&qlt=95&.v=1596511255000">Apple</a>',
                    description:
                        'The Scosche MagicGrip Wireless Charging Vent Mount charges your iPhone wirelessly as you drive. Simply place your iPhone SE (2022) on the Qi-charging surface, and the arms will shut and secure it. The Qi-sensing closure guarantees proper positioning for optimal charging. MagicGrip is simple to install on your vehicle’s vent and its patent-pending FreeFlow design never obstructs airflow.<br><br>This charging mount’s 360° rotating charging head is also useful for those who are often switching between portrait and landscape modes.',
                    moreInfoTitle: 'Get more information here',
                    moreInfoData: [
                        {
                            heading: 'Compatible models',
                            infoText: 'iPhone SE (2022)',
                        },
                        {
                            heading: 'Available at',
                            infoText: 'Official Apple website and authorised retailers',
                        },
                    ],
                    proTip: '',
                },
                {
                    header: '2 / mophie 3-in-1 Charging Stand',
                    title: '2 / mophie 3-in-1 Wireless Charging Stand',
                    image: AppleiPhoneSE2022Accessories_img2,
                    imageSource:
                        'Image Source: <a href="https://store.storeimages.cdn-apple.com/8756/as-images.apple.com/is/HP7M2?wid=1144&hei=1144&fmt=jpeg&qlt=95&.v=1602788660000">Apple</a>',
                    description:
                        'Apple fanboys and fangirls out there with more than one Apple device, meet the mophie 3-in-1 Wireless Charging Stand: a sleek central charging hub that lets you charge each one of your devices easily and conveniently, all in one place.<br><br>The charging stand has specific charging spots that are designed to charge the different devices. Simply set your device down and charging will begin once the magnetic components are aligned.  <br><br>It’s sleek and smooth finish will add to the overall style of your tabletop too.',
                    moreInfoTitle: 'Get more information here',
                    moreInfoData: [
                        {
                            heading: 'Compatible models',
                            infoText: 'iPhone SE (2022)',
                        },
                        {
                            heading: 'Available at',
                            infoText: 'Official Apple website and authorised retailers',
                        },
                    ],

                    proTip: '',
                },
                {
                    header: '3 / Casetify UV Sanitizer',
                    title: '3 / Casetify UV Sanitizer',
                    image: AppleiPhoneSE2022Accessories_img3,
                    imageSource:
                        'Image Source: <a href="https://ctgimage1.s3.amazonaws.com/cms/image/8fde9c842e4c1e6755f2b8e8a070f2a8.jpg">Casetify</a>',
                    description:
                        'The Casetify UV Sterilizer is a high-efficiency UV phone sanitiser with six Mercury-free UV lamps that work together to kill germs on your iPhone SE (2022). The steriliser comes with a wireless charging function that allows you to charge and clean your phone at the same time. Simply place your phone onto the wireless charging station and press a button to start charging.<br><br>The spacious steriliser can also be used to keep your other personal belongings such as your keys and small accessories clean. Instead of spending time sanitising your personal items one at a time, this UV sterilizer does it for you—all in just 3 minutes.',
                    moreInfoTitle: 'Get more information here',
                    moreInfoData: [
                        {
                            heading: 'Compatible models',
                            infoText: 'iPhone SE (2022)',
                        },
                        {
                            heading: 'Available at',
                            infoText: 'Official Casetify website',
                        },
                    ],
                    proTip: '',
                },
                {
                    header: '4 / Razer Kishi Gaming Controller',
                    title: '4 / Razer Kishi Mobile Gaming Controller',
                    image: AppleiPhoneSE2022Accessories_img4,
                    imageSource:
                        'Image Source: <a href="https://store.storeimages.cdn-apple.com/4668/as-images.apple.com/is/HPA72?wid=1144&hei=1144&fmt=jpeg&qlt=95&.v=1599760912000">Apple</a>',
                    description:
                        'Get ready to up your game with Razer Kishi Mobile Gaming Controller, an ergonomically-designed mobile gaming controller that comes with clickable analog thumbsticks for improved accuracy and tactile feedback.<br><br>The console-style gaming controller can be stretched and clamped securely on your iPhone SE (2022) so that it will not come loose while you game. For smooth and seamless control, the controller connects directly to your device’s charging port for instant button response.<br><br>If your iPhone starts running low on juice while you’re gaming with the controller still attached, simply hook up a lightning charging cable to the controller itself to get the device charging.',
                    moreInfoTitle: 'Get more information here',
                    moreInfoData: [
                        {
                            heading: 'Compatible models',
                            infoText: 'iPhone SE (2022)',
                        },
                        {
                            heading: 'Available at',
                            infoText: 'Official Apple website and authorised retailers',
                        },
                    ],
                    proTip: '',
                },
                {
                    header: '5 / Peak Design Bike Mount',
                    title: '5 / Peak Design Bike Mount',
                    image: AppleiPhoneSE2022Accessories_img5,
                    imageSource:
                        'Image Source: <a href="https://cdn.shopify.com/s/files/1/2986/1172/products/1-LIGHTBOX-Bike-OF-01_4ad1fa0f-2706-4690-b66f-ae4c0979daa9_1024x1024.jpg?v=1632196277">Peak Design</a>',
                    description:
                        'A well-known brand in the phone accessories space, Belkin and their ScreenForce line of tempered glass is a good choice for anyone who wishes to have peace of mind knowing that their prized iPhone is well protected and will stay pristine.<br><br>Each package comes with a premium chemically-engineered Japanese tempered glass with advanced impact protection, and the glass is treated with an antimicrobial agent to protect the screen protector from microbial growth. An Easy Align tray is also included for simple, precise, bubble-free application that you can perform on your own.',
                    moreInfoTitle: 'Get more information here',
                    moreInfoData: [
                        {
                            heading: 'Compatible models',
                            infoText: 'iPhone SE (2022)',
                        },
                        {
                            heading: 'Available at',
                            infoText: 'Official Peak Design website',
                        },
                    ],
                    proTip: '',
                },
            ],
            advertisement: {
                verticalAdImage: AdVertical,
                horizontalAdImage: AdHorizontal,
                mobileAdImage: AdMobile,
                adContent: {
                    asurionLogo: AsurionLogo,
                    line1Desktop: 'New iPhone 13 insurance',
                    line2Desktop: 'Premium starts from',
                    line1Mobile: 'the smart phone insurance.',
                    line2Mobile: '<b>iPhone 13</b> premium starts',
                    line3Mobile: 'from',
                    price: '$5.95',
                    paymentDurationText: '/mth',
                    phoneImageVertical: iPhone13AdImageDesktop,
                    phoneImageHotizontal: iPhone13AdImageHorizontal,
                    phoneImageMobile: iPhone13AdImageMobile,
                    buttonText: 'View phone plans',
                    buttonLink: '',
                    backgroundImageVertical: VerticalAdBackground,
                    backgroundImageHorizontal: HorizontalAdBackground,
                    backgroundImageMobile: MobileAdBackground,
                    legalText:
                        'Product issued by Liberty Mutual Insurance Company. Apple and iPhone are trademarks of Apple, Inc., registered in the U.S. and other countries and regions. Asurion is not affiliated with, or sponsored or endorsed by, Apple.',
                    legalTextMobile:
                        'Issued by Liberty Mutual Insurance Company AFSL 530842. T&Cs apply. Consider the PDS and TMD available online',
                },
            },
            mainKeyword: {
                name: 'Apple iPhone SE (2022)',
                slug: 'iPhone-SE-(2022)',
            },
            relatedKeywords: [
                {
                    name: 'Phone accessories',
                    slug: 'phone-accessories',
                },
            ],
            footerTitle: 'Apply for Asurion Mobile+ insurance for even more protection!',
            footerDescription:
                "A case can protect your phone from small drops but may not be able to shield your sleek new iPhone 13 from nastier accidents. For more peace of mind, Asurion Mobile+ <i>smart</i> phone insurance can cover your phone for accidental damage, loss, and theft.<br><br>Go to <a  data-cke-saved-href='www.asurion.com.au' href='https://www.asurion.com.au/' target='_blank'>www.asurion.com.au</a> to compare our plans and get extra protection for your phone today.",
            articlesYouMayLike: [
                {
                    title: 'How to Troubleshoot Someone Else’s Phone Remotely',
                    slug: '#',
                },
                {
                    title: '22 Great Wireless Chargers to Refuel Your Phone (or Watch)',
                    slug: '#',
                },
                {
                    title: 'Power Up Anywhere with Our Favorite Portable Chargers',
                    slug: '#',
                },
            ],
            keywords: [
                {
                    name: 'PROTECTION',
                    slug: '#',
                },
                {
                    name: 'ACCESSORIES',
                    slug: '#',
                },
                {
                    name: 'IPHONE',
                    slug: '#',
                },
                {
                    name: 'CASES',
                    slug: '#',
                },
                {
                    name: 'SHOPPING',
                    slug: '#',
                },
                {
                    name: 'HOW-TO',
                    slug: '#',
                },
            ],
            moreArticlesText: 'More phone accessories advice',
            moreArticles: [
                {
                    title: 'Wearables reduce your reliance on a phone while keeping you connected',
                    slug: '#',
                    image: ArticleWristy,
                    keyword: 'WRIST-Y BUSINESS',
                },
                {
                    title: "How to Master Google's AI Phone Call Features",
                    slug: '#',
                    image: ArticleGoogle,
                    keyword: 'HOW-TO',
                },
                {
                    title: 'DuckDuckGo Wants to Stop Apps From Tracking You on Android',
                    slug: '#',
                    image: ArticleDuck,
                    keyword: 'PRIVACY',
                },
                {
                    title: 'Trick Out Your iPhone12 with These Cases and Accessories',
                    slug: '#',
                    image: ArticleTrick,
                    keyword: 'GIFT GUIDE',
                },
            ],
        },
        //5
        {
            associatedDevice: 'iphone-se-3rd-generation',
            brand: 'Apple',
            title: '9 great iPhone SE 2022 phone cases',
            slug: '9-great-iphone-se-2022-phone-cases',
            author: 'Asurion Mobile+ Team',
            publishDate: '20 April 2022',
            description: 'Protect your iPhone SE with these phone cases from trusted brands.',
            bannerImage: Banner_image_for_iphoneSE_cases,
            bannerImageSourceText: 'Image source: Mous',
            bannerBackgroundGradient: 'linear-gradient(118.74deg, #1C1C1C 10.34%, #6B6B6C 100%)',
            articleImage: Apple_iPhone_SE_cases,
            introText:
                'Bought an iPhone SE 2022 recently and loving the photos and videos that it produces, but at the same time worried about your phone being a magnet for fingerprints and micro scratches? We’ve got you covered. Thankfully, buying an iPhone means there is a huge market and ecosystem for iPhone SE cases to cater to your every whim and fancy.',
            content: [
                {
                    header: '1 / elago Hybrid Clear Case',
                    title: '<b>1 / elago Hybrid Clear Case</b>: Plain to see',
                    image: AppleiPhoneSE2022PhoneCases_img1,
                    imageSource: '',
                    description:
                        'Minimalists rejoice! The elago Hybrid Clear Case allows you to show off the slim and clean lines of your iPhone while not compromising on design. It features a Thermoplastic Polyurethane (TPU) frame that protects your phone from drops, and a Polycarbonate back made from high quality resin that reduces yellowing ensuring that it stays crystal clear. The case also features a raised lip that helps protect the screen and the camera against scratches from direct surface contact.<br><br>Get the elago Hybrid Clear Case at <a href="https://www.amazon.com.au/elago-Compatible-Anti-Yellowing-Technology-Transparent/dp/B09T4YZ5HV/">Amazon</a>.',
                    moreInfoTitle: '',
                    moreInfoData: [],
                    proTip: '',
                },
                {
                    header: '2 / Spigen Ultra Hybrid',
                    title: '<b>2 / Spigen Ultra Hybrid</b>: Classic cult favourite',
                    image: AppleiPhoneSE2022PhoneCases_img2,
                    imageSource: '',
                    description:
                        'Spigen, a South Korean mobile phone accessory maker based in the United States needs no further introduction. Their Ultra Hybrid line is a cult favourite that focuses on providing your phone with excellent protection through a crystal-clear frame without breaking the bank.<br><br>Each Ultra Hybrid is Mil-grade certified with Air Cushion Technology®, which uses pockets of well, air, to absorb impact at the corners of the phone. Coupled with a slim design and edges that improve grip, the Ultra Hybrid is easy to recommend.<br><br>Besides Crystal Clear, the Ultra Hybrid also comes in Black and Rose Crystal. Purchase the Ultra Hybrid at <a href="https://www.spigenstore.com.au/spigen-iphone-se-2020-ultra-hybrid-2-2548884">Spigen’s official site</a>.',
                    moreInfoTitle: '',
                    moreInfoData: [],

                    proTip: '',
                },
                {
                    header: '3 / Casetify',
                    title: '3 / CASETiFY: Stylish skins',
                    image: AppleiPhoneSE2022PhoneCases_img3,
                    imageSource: '',
                    description:
                        'CASETiFY is a fast-growing global tech accessories brand, and a publisher and platform for creativity and self-expression to connect individuals. They collaborate with established brands such as Disney as well as indie artists.<br><br>Casetify’s Impact Cases, which are Military Grade tested to ensure that the device survives drops from up to 2m and are made with qìtech™, their proprietary shock-absorbing material which reduces impact by 95%. Each Impact Case provides great protection, while remaining extremely slim.<br><br>Visit the <a href="https://www.casetify.com/en_AU/iphone/iphone-se">Casetify page</a> to browse their extensive collection of cases and begin your phone customisation journey!',
                    moreInfoTitle: '',
                    moreInfoData: [],
                    proTip: '',
                },
                {
                    header: '4 / Otterbox Commuter',
                    title: '4 / Otterbox Commuter',
                    image: AppleiPhoneSE2022PhoneCases_img4,
                    imageSource: '',
                    description:
                        'For users who desire the protection from a rugged case but do not want to encase their phone in a thick outer shell, the Otterbox Commuter line of products is a great option. The Commuter offers DROP+ 3X protection, which enables the device to survive three cycles of being dropped 26 times from 1.2m. <br><br>In addition to superior drop protection over the typical case, the Commuter also has a port cover that protects your iPhone from dust.<br><br>Amazingly, the Commuter does not sacrifice on style. It comes in three two-tone colour options - Indigo (grey/navy), Ballet (peach/mauve) and Ocean (mint green/teal).<br><br>Get the Otterbox Commuter from <a href="https://www.otterbox.com.au/iphone-8-iphone-7/commuter-series-case/77-56651.html">Otterbox’s official website</a> or <a href="https://www.amazon.com.au/OtterBox-Commuter-Case-iPhone-2nd/dp/B08J7VDCV7/">Amazon.',
                    moreInfoTitle: '',
                    moreInfoData: [],
                    proTip: '',
                },
                {
                    header: '5 / Temdan',
                    title: '5 / Temdan: Get wild and wet without worry',
                    image: AppleiPhoneSE2022PhoneCases_img5,
                    imageSource: '',
                    description:
                        'The iPhone SE boasts impressive camera and video quality, and the Temdan serves as an excellent companion if you wish to use it as your dedicated outdoor and underwater photography/videography device.<br><br>The Temdan provides IP68 protection, which means it can keep your phone waterproof up to 1.2m underwater for one hour. This is significantly better than the iPhone’s IP67 rating, which keeps the phone waterproof at 1 meter for up to 30 minutes.<br><br>In addition to the improved waterproofing, the Temdan exceeds the Military Standard 810G-516 rating, enabling the phone to survive drops onto concrete 26 times from 1.2m.<br><br>Get the Temdan on <a href="https://www.amazon.com.au/Temdan-iPhone-SE-Waterproof-Shockproof/dp/B08CC4615Q/">Amazon</a>.',
                    moreInfoTitle: '',
                    moreInfoData: [],
                    proTip: '',
                },
                {
                    header: '6 / Cordking Silicone Ultra Slim',
                    title: '6 / Cordking Silicone Ultra Slim: A pop of colour',
                    image: AppleiPhoneSE2022PhoneCases_img6,
                    imageSource: '',
                    description:
                        'Want to add a pop of colour to your phone? How about being spoilt for choice with 23 pops of colour? That’s the huge number of colour options that Cordking’s Silicone case comes in. Lined with a soft microfiber that will not scratch your phone and a premium liquid silicone material that offers sleek but non-slip grip, it protects your phone while being easy to clean and maintain.<br><br>Get the Cordking Silicone case on <a href="https://www.amazon.com.au/Cordking-iPhone-Silicone-Shockproof-Microfiber/dp/B08QF9SH1N">Amazon</a>.',
                    moreInfoTitle: 'Get more information here',
                    moreInfoData: [
                        {
                            heading: 'Compatible models',
                            infoText: 'iPhone SE (2022)',
                        },
                        {
                            heading: 'Available at',
                            infoText: 'Official Peak Design website',
                        },
                    ],
                    proTip: '',
                },
                {
                    header: '7 / Apple Silicone Case',
                    title: '7 / Apple Silicone Case: The OG silicon case',
                    image: AppleiPhoneSE2022PhoneCases_img7,
                    imageSource: '',
                    description:
                        'Looking for a premium silicone case for your iPhone SE? Designed by Apple to be the perfect complement to your phone, the Apple Silicone Case has a soft microfibre lining on the inside which helps protect your iPhone from dust and micro-scratches while having silky, soft-touch finish on the outside, which has that great in-hand feel you won’t want to put your phone down.<br><br>Get yours at <a href="https://www.apple.com/au/shop/product/MN6G3FE/A/iphone-se-silicone-case-chalk-pink">Apple’s official site</a>.',
                    moreInfoTitle: '',
                    moreInfoData: [],
                    proTip: '',
                },
                {
                    header: '8 / Mous Limitless 2.0 Walnut Phone Case',
                    title: '8 / Mous Limitless 2.0 Walnut Phone Case: Wood you believe it?',
                    image: AppleiPhoneSE2022PhoneCases_img8,
                    imageSource: '',
                    description:
                        'If you’re searching for a premium case and love the in-hand feel of wood, look no further than the Mous Limitless 2.0 Walnut Phone Case. If Mous sounds familiar, it might be because you have watched ads of them doing extreme stuff like inviting people to hurl an iPhone X repeatedly outside an Apple store on launch day and having the phone escape unscathed each time.<br><br>The technology that enabled the iPhone X to survive is AiroShock, which surrounds the phone with micro air pockets that act as springs to dramatically dampen the impact of falls while not adding significant bulk to the overall footprint of the phone.<br><br>Get the Limitless 2.0 Walnut Case at <a href="https://row.mous.co/products/limitless-2-0-walnut-phone-case">Mous’ official site</a>.',
                    moreInfoTitle: '',
                    moreInfoData: [],
                    proTip: '',
                },
                {
                    header: '9 / Apple Leather Case',
                    title: '9 / Apple Leather Case: Elegant and sophisticated',
                    image: AppleiPhoneSE2022PhoneCases_img9,
                    imageSource: '',
                    description:
                        'Leather evokes feelings of premium and luxury, and nobody does this better Apple. Their Leader Cases are designed to fit snugly over the curves of your iPhone without adding bulk, and are made from specially tanned and finished European leather. This ensures that the outside feels soft to the touch and ages gracefully and develops a natural patina over time.<br><br>With great attention to detail, Apple designed the machined aluminium buttons to match the finish of your leather case, while a microfibre lining inside helps protect your iPhone from dust and micro-scratches.<br><br>The Apple Leather Case comes in 3 colors - Midnight Blue, Black and (PRODUCT)RED. <br><br>Get it at <a href="https://www.apple.com/au/shop/product/MXYL2FE/A/iphone-se-leather-case-productred">Apple’s official site</a>.',
                    moreInfoTitle: '',
                    moreInfoData: [],
                    proTip: '',
                },
            ],
            advertisement: {
                verticalAdImage: AdVertical,
                horizontalAdImage: AdHorizontal,
                mobileAdImage: AdMobile,
                adContent: {
                    asurionLogo: AsurionLogo,
                    line1Desktop: 'New iPhone 13 insurance',
                    line2Desktop: 'Premium starts from',
                    line1Mobile: 'the smart phone insurance.',
                    line2Mobile: '<b>iPhone 13</b> premium starts',
                    line3Mobile: 'from',
                    price: '$5.95',
                    paymentDurationText: '/mth',
                    phoneImageVertical: iPhone13AdImageDesktop,
                    phoneImageHotizontal: iPhone13AdImageHorizontal,
                    phoneImageMobile: iPhone13AdImageMobile,
                    buttonText: 'View phone plans',
                    buttonLink: '',
                    backgroundImageVertical: VerticalAdBackground,
                    backgroundImageHorizontal: HorizontalAdBackground,
                    backgroundImageMobile: MobileAdBackground,
                    legalText:
                        'Product issued by Liberty Mutual Insurance Company. Apple and iPhone are trademarks of Apple, Inc., registered in the U.S. and other countries and regions. Asurion is not affiliated with, or sponsored or endorsed by, Apple.',
                    legalTextMobile:
                        'Issued by Liberty Mutual Insurance Company AFSL 530842. T&Cs apply. Consider the PDS and TMD available online',
                },
            },
            mainKeyword: {
                name: 'iPhone SE (2022)',
                slug: 'iPhone-SE-(2022)',
            },
            relatedKeywords: [
                {
                    name: 'Phone cases',
                    slug: 'phone-cases',
                },
            ],
            footerTitle: 'Apply for Asurion Mobile+ insurance for even more protection!',
            footerDescription:
                "A case can protect your phone from small drops but may not be able to shield your sleek new iPhone 13 from nastier accidents. For more peace of mind, Asurion Mobile+ <i>smart</i> phone insurance can cover your phone for accidental damage, loss, and theft.<br><br>Go to <a  data-cke-saved-href='www.asurion.com.au' href='https://www.asurion.com.au/' target='_blank'>www.asurion.com.au</a> to compare our plans and get extra protection for your phone today.",
            articlesYouMayLike: [
                {
                    title: 'How to Troubleshoot Someone Else’s Phone Remotely',
                    slug: '#',
                },
                {
                    title: '22 Great Wireless Chargers to Refuel Your Phone (or Watch)',
                    slug: '#',
                },
                {
                    title: 'Power Up Anywhere with Our Favorite Portable Chargers',
                    slug: '#',
                },
            ],
            keywords: [
                {
                    name: 'PROTECTION',
                    slug: '#',
                },
                {
                    name: 'ACCESSORIES',
                    slug: '#',
                },
                {
                    name: 'IPHONE',
                    slug: '#',
                },
                {
                    name: 'CASES',
                    slug: '#',
                },
                {
                    name: 'SHOPPING',
                    slug: '#',
                },
                {
                    name: 'HOW-TO',
                    slug: '#',
                },
            ],
            moreArticlesText: 'More phone accessories advice',
            moreArticles: [
                {
                    title: 'Wearables reduce your reliance on a phone while keeping you connected',
                    slug: '#',
                    image: ArticleWristy,
                    keyword: 'WRIST-Y BUSINESS',
                },
                {
                    title: "How to Master Google's AI Phone Call Features",
                    slug: '#',
                    image: ArticleGoogle,
                    keyword: 'HOW-TO',
                },
                {
                    title: 'DuckDuckGo Wants to Stop Apps From Tracking You on Android',
                    slug: '#',
                    image: ArticleDuck,
                    keyword: 'PRIVACY',
                },
                {
                    title: 'Trick Out Your iPhone12 with These Cases and Accessories',
                    slug: '#',
                    image: ArticleTrick,
                    keyword: 'GIFT GUIDE',
                },
            ],
        },
        //6
        {
            associatedDevice: 'iphone-se-3rd-generation',
            brand: 'Apple',
            title: 'Top 5 Screen Protectors for iPhone SE (2022)',
            slug: 'top-5-screen-protectors-for-iphone-se-2022',
            author: 'Asurion Mobile+ Team',
            publishDate: '20 April 2022',
            description: 'Protect your phone from knocks and bumps.',
            bannerImage: Banner_image_for_iphoneSE_screen_protector,
            articleImage: Apple_iPhone_SE_Screen_protector,
            bannerImageSourceText: 'Image source: Zagg',
            bannerBackgroundGradient: 'linear-gradient(118.74deg, #1C1C1C 10.34%, #6B6B6C 100%)',
            introText:
                'At its price, the iPhone SE (2022) brings a lot to the table. It offers cutting-edge performance thanks to Apple’s A15 Bionic CPU, a superb primary camera, an HD touchscreen, and a battery that’s more powerful than its predecessor.<br><br>It does not, however, come equipped with Gorilla Glass on its front and back. While that’s not a dealbreaker, it does mean that the iPhone SE (2022) is more vulnerable to scratches from hard knocks and bumps as compared to other higher-end smartphones.<br><br>Fortunately, there is plenty you can do to protect your new phone. Investing in a screen protector is a great way to start.<br><br>We’ve put together a list of the best screen protectors for your iPhone SE (2022). From screen protectors that offer effortless application to those that are so thin you’d barely notice it’s there, you’ll have plenty of options to choose from.',
            content: [
                {
                    header: '1 / UAG',
                    title: '<b>1 / UAG</b>: Protection where it matters',
                    image: AppleiPhoneSE2022ScreenProtector_img1,
                    imageSource: 'Image credit: UAG',
                    description:
                        'The Glass Screen Protector Shield is an iPhone SE (2022) screen protector from UAG that covers all the bases to protect your phone where it matters. It offers 9H hardness, ultraclear and responsive design, smudge resistance, and precise fitting so that your phone will be shielded from scratches and scuff marks.<br><br>UAG also includes an applicator and a cleaning kit, so putting the protector on your phone should be simple.',
                    moreInfoTitle: 'Get more information here',
                    moreInfoData: [
                        {
                            heading: 'Available for',
                            infoText: 'iPhone SE (2022)',
                        },
                        {
                            heading: 'Get it at',
                            infoText:
                                '<a href="https://www.urbanarmorgear.com/">UAG official website</a> or at leading retailers',
                        },
                    ],
                    proTip: '',
                },
                {
                    header: '2 / Speck',
                    title: '<b>2 / Speck</b>: Thin but hardy',
                    image: AppleiPhoneSE2022ScreenProtector_img2,
                    imageSource: 'Image credit: Speck',
                    description:
                        'The Speck ShieldView Glass iPhone SE (2022) Screen Protector is thin and light. The ultra-thin design precisely fits your phone and does not interfere with the touch sensitivity of your screen. Despite its thin construction, ShieldView Glass carries 9H hardness rating and is tough and sturdy. Speck claims that it won’t shatter even if you drop your phone.<br><br>The screen protector sports a glossy coating that repels fingerprints, grime, and smudges to preserve the clarity of your screen. For even more protection for your phone, Speck offers a wide range of phone cases that are designed to be compatible with its screen protectors. ',
                    moreInfoTitle: 'Get more information here',
                    moreInfoData: [
                        {
                            heading: 'Available for',
                            infoText: 'iPhone SE (2022)',
                        },
                        {
                            heading: 'Get it at',
                            infoText: '<a href="https://speckproducts.com/">Speck official website</a>',
                        },
                    ],

                    proTip: '',
                },
                {
                    header: '3 / Spigen',
                    title: '<b>3 / Spigen</b>: Effortless application',
                    image: AppleiPhoneSE2022ScreenProtector_img3,
                    imageSource: 'Image credit: Spigen',
                    description:
                        'Spigen is well-known for its protective phone covers, but did you know that the brand offers compatible screen protectors? The Spigen AlignMaster GLAS.tR Screen Protector is a tempered glass cover with a 9H hardness rating and an oleophobic coating for daily fingerprint resistance. If you have a Spigen case, you’ll love that this screen protector is made to be compatible with every Spigen phone case.<br><br>The Glas.tR AlignMaster also comes with a tray that is designed to eliminate the time spent on installation. Installation is a breeze; simply place the tray over the phone, press the centre, and the glass will adhere in place.',
                    moreInfoTitle: 'Get more information here',
                    moreInfoData: [
                        {
                            heading: 'Available for',
                            infoText: 'iPhone SE (2022)',
                        },
                        {
                            heading: 'Get it at',
                            infoText:
                                '<a href="https://www.spigen.com/">Spigen official website</a> or at leading retailers',
                        },
                    ],
                    proTip: '',
                },
                {
                    header: '4 / SpectreShield',
                    title: '<b>4 / SpectreShield</b>: Military-grade film shields',
                    image: AppleiPhoneSE2022ScreenProtector_img4,
                    imageSource: 'Image credit: SpectreShield',
                    description:
                        'The SpectreShield Flexible Tech screen protector is a military-grade film shield designed to provide maximum protection to your screen despite its virtually invisible design. Made with durability and visibility in mind, the screen protector is made of an advanced optical material that ensures you get the highest visibility on your screen.<br><br>For an error-free application, the film is installed via a "wet method" that allows multiple attempts for you to align the film and even wash off dirt if needed. Those who prefer a tempered glass screen protector can consider SpectreShield’s Glass Tempered Screen Protector which offers multi-layered glass protection and perfect edge-to-edge alignment.',
                    moreInfoTitle: 'Get more information here',
                    moreInfoData: [
                        {
                            heading: 'Available for',
                            infoText: 'iPhone SE (2022)',
                        },
                        {
                            heading: 'Get it at',
                            infoText: '<a href="https://www.spectreshield.com/">SpectreShield</a> official website',
                        },
                    ],
                    proTip: '',
                },
                {
                    header: '5 / Eiger',
                    title: '<b>5 / Eiger</b>: With Anti-microbial technology ',
                    image: AppleiPhoneSE2022ScreenProtector_img5,
                    imageSource: 'Image credit: Eiger',
                    description:
                        'The Eiger Mountain+ Glass 2.5D Screen Protector is a case-friendly screen cover for iPhone SE (2022). Made with high-quality alumino-silicate glass, it features a 0.33mm ultra-thin, scratch-resistant design with high definition clarity.<br><br>For even more protection, Eiger also treated the screen protector with an anti-microbial technology that kills 99.9% of surface bacteria and viruses. The Eiger Mountain+ Glass 2.5D Screen Protector also comes with an oleophobic coating that creates a long-lasting, oil-resistant, and anti-fingerprint barrier for easy maintenance and protection against dirt and dust.',
                    moreInfoTitle: 'Get more information here',
                    moreInfoData: [
                        {
                            heading: 'Available for',
                            infoText: 'iPhone SE (2022)',
                        },
                        {
                            heading: 'Get it at',
                            infoText: '<a href="https://eigerprotection.com/">Eiger</a> official website',
                        },
                    ],
                    proTip: '',
                },
            ],
            advertisement: {
                verticalAdImage: AdVertical,
                horizontalAdImage: AdHorizontal,
                mobileAdImage: AdMobile,
                adContent: {
                    asurionLogo: AsurionLogo,
                    line1Desktop: 'New iPhone 13 insurance',
                    line2Desktop: 'Premium starts from',
                    line1Mobile: 'the smart phone insurance.',
                    line2Mobile: '<b>iPhone 13</b> premium starts',
                    line3Mobile: 'from',
                    price: '$5.95',
                    paymentDurationText: '/mth',
                    phoneImageVertical: iPhone13AdImageDesktop,
                    phoneImageHotizontal: iPhone13AdImageHorizontal,
                    phoneImageMobile: iPhone13AdImageMobile,
                    buttonText: 'View phone plans',
                    buttonLink: '',
                    backgroundImageVertical: VerticalAdBackground,
                    backgroundImageHorizontal: HorizontalAdBackground,
                    backgroundImageMobile: MobileAdBackground,
                    legalText:
                        'Product issued by Liberty Mutual Insurance Company. Apple and iPhone are trademarks of Apple, Inc., registered in the U.S. and other countries and regions. Asurion is not affiliated with, or sponsored or endorsed by, Apple.',
                    legalTextMobile:
                        'Issued by Liberty Mutual Insurance Company AFSL 530842. T&Cs apply. Consider the PDS and TMD available online',
                },
            },
            mainKeyword: {
                name: 'iPhone SE (2022)',
                slug: 'iPhone-SE-(2022)',
            },
            relatedKeywords: [
                {
                    name: 'Phone screen protectors',
                    slug: 'phone-screen-protectors',
                },
            ],
            footerTitle: 'Apply for Asurion Mobile+ insurance for even more protection!',
            footerDescription:
                "A case can protect your phone from small drops but may not be able to shield your sleek new iPhone 13 from nastier accidents. For more peace of mind, Asurion Mobile+ <i>smart</i> phone insurance can cover your phone for accidental damage, loss, and theft.<br><br>Go to <a  data-cke-saved-href='www.asurion.com.au' href='https://www.asurion.com.au/' target='_blank'>www.asurion.com.au</a> to compare our plans and get extra protection for your phone today.",
            articlesYouMayLike: [
                {
                    title: 'How to Troubleshoot Someone Else’s Phone Remotely',
                    slug: '#',
                },
                {
                    title: '22 Great Wireless Chargers to Refuel Your Phone (or Watch)',
                    slug: '#',
                },
                {
                    title: 'Power Up Anywhere with Our Favorite Portable Chargers',
                    slug: '#',
                },
            ],
            keywords: [
                {
                    name: 'PROTECTION',
                    slug: '#',
                },
                {
                    name: 'ACCESSORIES',
                    slug: '#',
                },
                {
                    name: 'IPHONE',
                    slug: '#',
                },
                {
                    name: 'CASES',
                    slug: '#',
                },
                {
                    name: 'SHOPPING',
                    slug: '#',
                },
                {
                    name: 'HOW-TO',
                    slug: '#',
                },
            ],
            moreArticlesText: 'More phone accessories advice',
            moreArticles: [
                {
                    title: 'Wearables reduce your reliance on a phone while keeping you connected',
                    slug: '#',
                    image: ArticleWristy,
                    keyword: 'WRIST-Y BUSINESS',
                },
                {
                    title: "How to Master Google's AI Phone Call Features",
                    slug: '#',
                    image: ArticleGoogle,
                    keyword: 'HOW-TO',
                },
                {
                    title: 'DuckDuckGo Wants to Stop Apps From Tracking You on Android',
                    slug: '#',
                    image: ArticleDuck,
                    keyword: 'PRIVACY',
                },
                {
                    title: 'Trick Out Your iPhone12 with These Cases and Accessories',
                    slug: '#',
                    image: ArticleTrick,
                    keyword: 'GIFT GUIDE',
                },
            ],
        },
        //7
        {
            associatedDevice: 'galaxy-s22-5g',
            brand: 'Samsung',
            title: 'Phone Accessories for You to Make the Most of Your Samsung Galaxy S22',
            slug: 'phone-accessories-for-you-to-make-the-most-of-your-samsung-galaxy-s22',
            author: 'Asurion Mobile+ Team',
            publishDate: '20 April 2022',
            description: 'Super charge your flagship phone with these extra accessories.',
            bannerImage: Banner_image_for_samsung_s22_accessory,
            articleImage: Samsung_galaxy_s22_accessories,
            bannerImageSourceText: 'Image Source: Samsung',
            bannerBackgroundGradient: 'linear-gradient(118.74deg, #0A0A0A 10.34%, #373839 100%)',
            introText:
                'With flagship specs and exciting new features, the Samsung Galaxy S22 series, which consists three phones—the Galaxy S22, Galaxy S22 Plus, and Galaxy S22 Ultra, is impressive on its own. But if you really want to make the most of your new phone, why not invest in phone accessories such as wireless chargers, power banks, mounts, and more?',
            content: [
                {
                    header: '1 / Samsung Super Fast Wireless Charger',
                    title: '1 / Samsung Super Fast Wireless Charger Duo',
                    image: SamsungGalaxyS22Accessories_img1,
                    imageSource:
                        'Image source: <a href="https://images.samsung.com/sg/smartphones/galaxy-s22/accessories/images/chargers/galaxy-s22_accessories_super-fast-wireless-charger-duo_white.jpg">Samsung</a>',
                    description:
                        'The Samsung wireless charger Duo is a wireless charging pad that enables you to recharge two of your compatible devices quickly with a maximum charging power of 15W. On the left, you’ll find a charging area that is dedicated to your smartphone or Galaxy Buds. The right charging area is for your Galaxy Watch or Galaxy Buds. <br><br>Equipped with a built-in cooling system with a fan, the Wireless Charger Pad charges up your phone quickly and with less power consumption. <br><br>Thanks to the wireless charger’s slim and compact design, you can bring it around conveniently and whip it out whenever you need a quick pick me up. It is also sleek enough to perfectly blend into any space.',
                    moreInfoTitle: 'Get more information here',
                    moreInfoData: [
                        {
                            heading: 'Compatible models',
                            infoText: 'Galaxy S22, Galaxy S22 Plus, and Galaxy S22 Ultra',
                        },
                        {
                            heading: 'Available at',
                            infoText: 'Official Samsung website and authorised retailers',
                        },
                    ],
                    proTip: '',
                },
                {
                    header: '2 / Samsung Wireless Car Charger',
                    title: '2 / Samsung Wireless Car Charger',
                    image: SamsungGalaxyS22Accessories_img2,
                    imageSource:
                        'Image source: <a href="https://images.samsung.com/is/image/samsung/p6pim/ca/ep-h5300cbegca/gallery/ca-wireless-car-charger-ep-h5300-ep-h5300cbegca-530696384?$684_547_PNG$">Samsung</a>',
                    description:
                        'Those who are out driving throughout the day can consider getting a wireless car charger. This 9W Wireless Car Charger supports Samsung Galaxy Devices and can charge your mobile device wirelessly in your car with ease.<br><br>If you’re often annoyed by stiff phone clamps that are difficult to operate and “squeeze” your phone, you’d love the simplicity that this car charger brings. Simply place your device on the charger and the clamp will close automatically to hold your phone securely. The charger mounts tightly to car air vents of various sizes and shapes and the clamp even safely releases your phone when the car is turned off. You can also switch between portrait and landscape mode by rotating the charger.<br><br>In addition to your Samsung Galaxy S22, the charger supports up to 7.5W for iPhones and even powers Qi-enabled devices up to 5W.',
                    moreInfoTitle: 'Get more information here',
                    moreInfoData: [
                        {
                            heading: 'Compatible models',
                            infoText: 'Galaxy S22, Galaxy S22 Plus, and Galaxy S22 Ultra',
                        },
                        {
                            heading: 'Available at',
                            infoText: 'Official Samsung website and authorised retailers',
                        },
                    ],

                    proTip: '',
                },
                {
                    header: '3 / Belkin BOOST↑CHARGE™ Power Bank',
                    title: '3 / Belkin BOOST↑CHARGE™ Power Bank 10K + Stand Play Series',
                    image: SamsungGalaxyS22Accessories_img3,
                    imageSource:
                        'Image Source: <a href="https://www.belkin.com/images/product_webdam/107832944/renditions/webdam.web.1000.1000.jpeg">Belkin</a>',
                    description:
                        'The BOOST↑CHARGE™ Power Bank 10K + Stand Play Series is your personal, powerful, and portable gaming system that allows you to charge while you play. The 10,000 mAh power bank offers more than 36 hours of battery life and gives more than enough power to your device to keep you playing or streaming without interruption.<br><br>For convenient viewing, BOOST↑CHARGE™ Power Bank 10K + Stand Play Series features a retractable stand that is designed to hold your Samsung Galaxy S22 at the perfect angle for viewing while you charge so you always get the best seat in the house.<br><br>The power bank is fitted with dual charging ports that can simultaneously charge two devices. That means you can power up your device and a gaming controller while you play—every mobile gamer’s dream.',
                    moreInfoTitle: 'Get more information here',
                    moreInfoData: [
                        {
                            heading: 'Compatible models',
                            infoText: 'Galaxy S22, Galaxy S22 Plus, and Galaxy S22 Ultra',
                        },
                        {
                            heading: 'Available at',
                            infoText: 'Official Belkin site, Amazon',
                        },
                    ],
                    proTip: '',
                },
                {
                    header: '4 / ITFIT UV Steriliser with Wireless Charging',
                    title: '4 / ITFIT UV Steriliser with Wireless Charging',
                    image: SamsungGalaxyS22Accessories_img4,
                    imageSource:
                        'Image Source: <a href="https://images.samsung.com/is/image/samsung/sg-feature-wireless-charging-253961659?imwidth=1536">Samsung</a>',
                    description:
                        'If you’re looking for a device that is dedicated to keeping your personal belongings germ-free, the ITFIT UV Steriliser with Wireless Charging is worth considering. This UV steriliser has been tested and proven by 3rd party laboratories to effectively kill up to 99% of bacteria within 10 minutes, working to keep your personal items and devices contamination-free. The best part? You can sterilise your phone and charge it up at the same time!<br><br>With a spacious design, the steriliser can accommodate not just your Galaxy S22 device but other items such as Galaxy Buds, and even your spectacles. Opening and closing the case is fuss-free with a magnetic cover that gives airtight closure.',
                    moreInfoTitle: 'Get more information here',
                    moreInfoData: [
                        {
                            heading: 'Compatible models',
                            infoText: 'Galaxy S22, Galaxy S22 Plus, and Galaxy S22 Ultra',
                        },
                        {
                            heading: 'Available at',
                            infoText: 'Official Samsung website and authorised retailers',
                        },
                    ],
                    proTip: '',
                },
                {
                    header: '5 / FitClic Neo Running kit ',
                    title: '5 / FitClic Neo Running kit ',
                    image: SamsungGalaxyS22Accessories_img5,
                    imageSource:
                        'Image Source: <a href="https://www.tigrasport.com/device/1264-copy-of-fitclic-neo-running-kit-for-samsung-galaxy-s21.html">Tigra Sport</a>',
                    description:
                        'FitClic Neo Running kit is the world’s first magnetic twist-locking system for smartphones. It is a versatile mounting system that allows you to effortlessly attach and detach your Galaxy S22 and keep it securely mounted in place even under the most demanding conditions.<br><br>Despite its slim and pocket-friendly design, the FitClic Neo Running Kit is rugged and built for maximum protection. It sports a composite construction with polycarbonate with soft TPU and comes with shock-absorbing structures as well as raised edges to protect your phone from scratches and scuff marks.',
                    moreInfoTitle: 'Get more information here',
                    moreInfoData: [
                        {
                            heading: 'Compatible models',
                            infoText: 'Galaxy S22, Galaxy S22 Plus, and Galaxy S22 Ultra',
                        },
                        {
                            heading: 'Available at',
                            infoText: 'Official Tigra Sports website',
                        },
                    ],
                    proTip: '',
                },
            ],
            advertisement: {
                verticalAdImage: AdVertical,
                horizontalAdImage: AdHorizontal,
                mobileAdImage: AdMobile,
                adContent: {
                    asurionLogo: AsurionLogo,
                    line1Desktop: 'New iPhone 13 insurance',
                    line2Desktop: 'Premium starts from',
                    line1Mobile: 'the smart phone insurance.',
                    line2Mobile: '<b>iPhone 13</b> premium starts',
                    line3Mobile: 'from',
                    price: '$5.95',
                    paymentDurationText: '/mth',
                    phoneImageVertical: iPhone13AdImageDesktop,
                    phoneImageHotizontal: iPhone13AdImageHorizontal,
                    phoneImageMobile: iPhone13AdImageMobile,
                    buttonText: 'View phone plans',
                    buttonLink: '',
                    backgroundImageVertical: VerticalAdBackground,
                    backgroundImageHorizontal: HorizontalAdBackground,
                    backgroundImageMobile: MobileAdBackground,
                    legalText:
                        'Product issued by Liberty Mutual Insurance Company. Apple and iPhone are trademarks of Apple, Inc., registered in the U.S. and other countries and regions. Asurion is not affiliated with, or sponsored or endorsed by, Apple.',
                    legalTextMobile:
                        'Issued by Liberty Mutual Insurance Company AFSL 530842. T&Cs apply. Consider the PDS and TMD available online',
                },
            },
            mainKeyword: {
                name: 'Samsung Galaxy S22',
                slug: 'Samsung-Galaxy-S22',
            },
            relatedKeywords: [
                {
                    name: 'Phone accessories',
                    slug: 'phone-accessories',
                },
            ],
            footerTitle: 'Apply for Asurion Mobile+ insurance for even more protection!',
            footerDescription:
                "A case can protect your phone from small drops but may not be able to shield your sleek new iPhone 13 from nastier accidents. For more peace of mind, Asurion Mobile+ <i>smart</i> phone insurance can cover your phone for accidental damage, loss, and theft.<br><br>Go to <a  data-cke-saved-href='www.asurion.com.au' href='https://www.asurion.com.au/' target='_blank'>www.asurion.com.au</a> to compare our plans and get extra protection for your phone today.",
            articlesYouMayLike: [
                {
                    title: 'How to Troubleshoot Someone Else’s Phone Remotely',
                    slug: '#',
                },
                {
                    title: '22 Great Wireless Chargers to Refuel Your Phone (or Watch)',
                    slug: '#',
                },
                {
                    title: 'Power Up Anywhere with Our Favorite Portable Chargers',
                    slug: '#',
                },
            ],
            keywords: [
                {
                    name: 'PROTECTION',
                    slug: '#',
                },
                {
                    name: 'ACCESSORIES',
                    slug: '#',
                },
                {
                    name: 'IPHONE',
                    slug: '#',
                },
                {
                    name: 'CASES',
                    slug: '#',
                },
                {
                    name: 'SHOPPING',
                    slug: '#',
                },
                {
                    name: 'HOW-TO',
                    slug: '#',
                },
            ],
            moreArticlesText: 'More phone accessories advice',
            moreArticles: [
                {
                    title: 'Wearables reduce your reliance on a phone while keeping you connected',
                    slug: '#',
                    image: ArticleWristy,
                    keyword: 'WRIST-Y BUSINESS',
                },
                {
                    title: "How to Master Google's AI Phone Call Features",
                    slug: '#',
                    image: ArticleGoogle,
                    keyword: 'HOW-TO',
                },
                {
                    title: 'DuckDuckGo Wants to Stop Apps From Tracking You on Android',
                    slug: '#',
                    image: ArticleDuck,
                    keyword: 'PRIVACY',
                },
                {
                    title: 'Trick Out Your iPhone12 with These Cases and Accessories',
                    slug: '#',
                    image: ArticleTrick,
                    keyword: 'GIFT GUIDE',
                },
            ],
        },
        //8
        {
            associatedDevice: 'galaxy-s22-5g',
            brand: 'Samsung',
            title: 'Top 6 Phone Cases to Protect Your Samsung Galaxy S22',
            slug: 'top-6-phone-cases-to-protect-your-samsung-galaxy-s22',
            author: 'Asurion Mobile+ Team',
            publishDate: '20 April 2022',
            description:
                'From rugged phone cases to aesthetic and “instagrammable” ones to anything in between, here are the top 6 phone cases for you to protect your Samsung Galaxy S22. ',
            bannerImage: Banner_image_for_Samsung_s22_cases,
            articleImage: Samsung_galaxy_s22_phone_cases,
            bannerImageSourceText: 'Image Source: UAG',
            bannerBackgroundGradient: 'linear-gradient(118.74deg, #0A0A0A 10.34%, #373839 100%)',
            introText:
                'From record-breaking orders to exciting new features, the Samsung Galaxy S22 series, which consists of three phones—the Galaxy S22, Galaxy S22 Plus, and Galaxy S22 Ultra, has outperformed its predecessors in more ways than one.<br><br>For some, Samsung’s decision to fit its latest set of flagship smartphones with Gorilla Glass Victus Plus is particularly enticing. The latest form of Corning protection for smartphones offers added durability, which is great news if you’re generally a klutz.<br><br>Gorilla Glass Victus Plus, however, does not offer total immunity to your Galaxy S22, Galaxy S22 Plus, or Galaxy S22 Ultra. Your phone is still vulnerable to knocks and bumps. Besides, you paid top dollar for your phone and it only makes sense for you to protect your investment with something as simple as a phone case.',
            content: [
                {
                    header: '1 / UAG',
                    title: '<b>1 / Urban Armor Gear<b>: All-terrain protection ',
                    image: SamsungGalaxyS22PhoneCases_img1,
                    imageSource: 'Image source: UAG',
                    description:
                        'The UAG Monarch series sports that classic rugged look that we know and love UAG for. The “all-terrain”, rugged protective case, is equipped with a comprehensive and premium 5-layer protection: a shock-resistant core, a signature armour frame, layered with top-grain leather (or carbon fibre), a polycarbonate shear plate, alloy metal hardware, and impact-resistant rubber surround.<br><br>With 20ft (6m) drop protection and a 10-year warranty, this may just be the only case you need to purchase for your new Galaxy S22.<br><br>If you’re not a fan of the rugged look, consider UAG’s other series like the Pathfinder series, the Civilian series, or the Plasma series. You may even find some features that were absent from the Monarch series. The UAG Plasma, for example, has been tested for medical environments—using medical-grade cleaners and disinfectants on your case will not compromise its integrity.',
                    moreInfoTitle: 'Get more information here',
                    moreInfoData: [
                        {
                            heading: 'Compatible models',
                            infoText: 'Galaxy S22, Galaxy S22 Plus, and Galaxy S22 Ultra',
                        },
                        {
                            heading: 'Buy it from',
                            infoText:
                                '<a href="https://www.urbanarmorgear.com/">UAG official website</a> or at leading retailers',
                        },
                    ],
                    proTip: '',
                },
                {
                    header: '2 / Supcase',
                    title: '<b>2 / Supcase</b>: Protection on another level',
                    image: SamsungGalaxyS22PhoneCases_img2,
                    imageSource: 'Image source: Supcase',
                    description:
                        'If you’re looking for a phone case that has won awards, the Supcase Unicorn Beetle Pro may just be the case for you. The winner of CNET’s Annual Drop Test has been tested for falls ranging from 20 ft (6m) to 50 ft (15m).<br><br>Made of unbending polycarbonate and shock-absorbing TPU, the Supcase Unicorn Beetle Pro offers 360° protection from drops, bumps, and impact. The case also comes with raised bezels that protect the Galaxy S22’s gorgeous display and lenses from potentially damaging surfaces.<br><br>Supcase even included port plugs, a built-in kickstand, and—for the Galaxy S22 Ultra version— an open cutout for easy access to S-Pen.<br><br>If you’re an average user who doesn’t need the all-out protection of the Unicorn Beetle Pro, sleeker but still adequately protective cases like the UB Style or the UB Exo may be better options.',
                    moreInfoTitle: 'Get more information here',
                    moreInfoData: [
                        {
                            heading: 'Compatible models',
                            infoText: 'Galaxy S22, Galaxy S22 Plus, and Galaxy S22 Ultra',
                        },
                        {
                            heading: 'Buy it from',
                            infoText: '<a href="https://www.supcase.com/">Supcase official website</a> or Amazon',
                        },
                    ],

                    proTip: '',
                },
                {
                    header: '3 / Otterbox',
                    title: '<b>3 / Otterbox</b>: Sleekly rugged',
                    image: SamsungGalaxyS22PhoneCases_img3,
                    imageSource: 'Image source: Otterbox',
                    description:
                        'If you’re looking for a case that offers the level of protection that comes with a rugged case without that iconic chunky look, consider the Otterbox Commuter Series Case. The Galaxy S22 case comes in a variety of fun colours like pink and blue.<br><br>Despite its slim and pocket-friendly design, the case offers drop protection that surpasses the military standard. A “silver-based antimicrobial additive” is even weaved into the Otterbox Commuter’s case materials to inhibit microbial growth and protect the case from common bacteria.<br><br>For even more design options, check out the Symmetry series. Cases in this series come in unique and attract colours and patterns while offering the same level of protection and features. ',
                    moreInfoTitle: 'Get more information here',
                    moreInfoData: [
                        {
                            heading: 'Compatible models',
                            infoText: 'Galaxy S22, Galaxy S22 Plus, and Galaxy S22 Ultra',
                        },
                        {
                            heading: 'Buy it from',
                            infoText:
                                '<a href="http://otterbox.com.au/">Otterbox official website</a> or at leading retailers',
                        },
                    ],
                    proTip: '',
                },
                {
                    header: '4 / Spigen',
                    title: '<b>4 / Spigen</b>: Protection without the bulk',
                    image: SamsungGalaxyS22PhoneCases_img4,
                    imageSource: 'Image source: Spigen',
                    description:
                        'The Spigen Tough Armor Case is one of the brand’s top-selling phone cases. With military-grade certified drop protection and Air Cushion technology, your phone will be well-protected from bumps and falls. Raised corners surrounding the camera offer additional protection for one of the S22’s greatest assets.<br><br>The case also comes fitted with a built-in kickstand for you to catch your favourite series on the go. Spigen even manages to fit all these features into a slim and sleek form factor.<br><br>If you’re conscious about budget, you can check out Spigen’s mid-range options like the Core Armor, Optik Crystal, or Liquid Air.',
                    moreInfoTitle: 'Get more information here',
                    moreInfoData: [
                        {
                            heading: 'Compatible models',
                            infoText: 'Galaxy S22, Galaxy S22 Plus, and Galaxy S22 Ultra',
                        },
                        {
                            heading: 'Buy it from',
                            infoText:
                                '<a href="https://www.spigen.com/">Spigen official website</a> or at leading retailers',
                        },
                    ],
                    proTip: '',
                },
                {
                    header: '5 / Casetify',
                    title: '<b>5 / Casetify</b>: Pretty prints',
                    image: SamsungGalaxyS22PhoneCases_img5,
                    imageSource: 'Image credit: Casetify',
                    description:
                        'Scroll through Casetify’s site and you’ll find an extensive selection of phone cases in various eye-catching prints and designs. From cases with insanely detailed designs to expensive-looking leather cases, you’ll probably have trouble taking your pick.<br><br>If you don’t see something you like, Casetify provides the option of customisation. You can build your very own case by choosing your own fonts, layout, text, colours, and prints. You’ll even have the choice to bedazzle your case with accessories like colourful phone straps or decorative elements like glitter and sand.<br><br>Casetify doesn’t skimp on protection either. Cases offer different protective features like raised bezels, scratch resistance, drop protection, anti-microbial coating, and more, depending on which series you choose.',
                    moreInfoTitle: 'Get more information here',
                    moreInfoData: [
                        {
                            heading: 'Compatible models',
                            infoText: 'Galaxy S22, Galaxy S22 Plus, and Galaxy S22 Ultra',
                        },
                        {
                            heading: 'Buy it from',
                            infoText: '<a href="https://www.casetify.com/en_AU/">Casetify official website</a>',
                        },
                    ],
                    proTip: '',
                },
                {
                    header: '6 / Burga',
                    title: '<b>6 / Burga</b>: Statement pieces',
                    image: SamsungGalaxyS22PhoneCases_img6,
                    imageSource: 'Image credit: Burga',
                    description:
                        'Burga phone cases stand comfortably at the intersection between durable and pretty. Their phone cases come in two case types: Snap, an ultra-thin, sleek, and lightweight case with raised case bezels, and Tough, a dual-layer case that offers all-round shockproof protection and heavy-duty coverage while keeping to a slim profile.<br><br>Whichever case type you choose, Burga’s unique hardshell phone cases are available in an assortment of trendy designs that will surely elevate your mirror selfie.',
                    moreInfoTitle: 'Get more information here',
                    moreInfoData: [
                        {
                            heading: 'Warranty?',
                            infoText: 'One (1) year warranty',
                        },
                        {
                            heading: 'Compatible models',
                            infoText: 'Galaxy S22, Galaxy S22 Plus, and Galaxy S22 Ultra',
                        },
                        {
                            heading: 'Available at',
                            infoText: '<a href="https://au.burga.com/">Burga official website</a>',
                        },
                    ],
                    proTip: '',
                },
            ],
            advertisement: {
                verticalAdImage: AdVertical,
                horizontalAdImage: AdHorizontal,
                mobileAdImage: AdMobile,
                adContent: {
                    asurionLogo: AsurionLogo,
                    line1Desktop: 'New iPhone 13 insurance',
                    line2Desktop: 'Premium starts from',
                    line1Mobile: 'the smart phone insurance.',
                    line2Mobile: '<b>iPhone 13</b> premium starts',
                    line3Mobile: 'from',
                    price: '$5.95',
                    paymentDurationText: '/mth',
                    phoneImageVertical: iPhone13AdImageDesktop,
                    phoneImageHotizontal: iPhone13AdImageHorizontal,
                    phoneImageMobile: iPhone13AdImageMobile,
                    buttonText: 'View phone plans',
                    buttonLink: '',
                    backgroundImageVertical: VerticalAdBackground,
                    backgroundImageHorizontal: HorizontalAdBackground,
                    backgroundImageMobile: MobileAdBackground,
                    legalText:
                        'Product issued by Liberty Mutual Insurance Company. Apple and iPhone are trademarks of Apple, Inc., registered in the U.S. and other countries and regions. Asurion is not affiliated with, or sponsored or endorsed by, Apple.',
                    legalTextMobile:
                        'Issued by Liberty Mutual Insurance Company AFSL 530842. T&Cs apply. Consider the PDS and TMD available online',
                },
            },
            mainKeyword: {
                name: 'Samsung Galaxy S22',
                slug: 'Samsung-Galaxy-S22',
            },
            relatedKeywords: [
                {
                    name: 'Phone cases',
                    slug: 'phone-cases',
                },
            ],
            footerTitle: 'Apply for Asurion Mobile+ insurance for even more protection!',
            footerDescription:
                "A case can protect your phone from small drops but may not be able to shield your sleek new iPhone 13 from nastier accidents. For more peace of mind, Asurion Mobile+ <i>smart</i> phone insurance can cover your phone for accidental damage, loss, and theft.<br><br>Go to <a  data-cke-saved-href='www.asurion.com.au' href='https://www.asurion.com.au/' target='_blank'>www.asurion.com.au</a> to compare our plans and get extra protection for your phone today.",
            articlesYouMayLike: [
                {
                    title: 'How to Troubleshoot Someone Else’s Phone Remotely',
                    slug: '#',
                },
                {
                    title: '22 Great Wireless Chargers to Refuel Your Phone (or Watch)',
                    slug: '#',
                },
                {
                    title: 'Power Up Anywhere with Our Favorite Portable Chargers',
                    slug: '#',
                },
            ],
            keywords: [
                {
                    name: 'PROTECTION',
                    slug: '#',
                },
                {
                    name: 'ACCESSORIES',
                    slug: '#',
                },
                {
                    name: 'IPHONE',
                    slug: '#',
                },
                {
                    name: 'CASES',
                    slug: '#',
                },
                {
                    name: 'SHOPPING',
                    slug: '#',
                },
                {
                    name: 'HOW-TO',
                    slug: '#',
                },
            ],
            moreArticlesText: 'More phone accessories advice',
            moreArticles: [
                {
                    title: 'Wearables reduce your reliance on a phone while keeping you connected',
                    slug: '#',
                    image: ArticleWristy,
                    keyword: 'WRIST-Y BUSINESS',
                },
                {
                    title: "How to Master Google's AI Phone Call Features",
                    slug: '#',
                    image: ArticleGoogle,
                    keyword: 'HOW-TO',
                },
                {
                    title: 'DuckDuckGo Wants to Stop Apps From Tracking You on Android',
                    slug: '#',
                    image: ArticleDuck,
                    keyword: 'PRIVACY',
                },
                {
                    title: 'Trick Out Your iPhone12 with These Cases and Accessories',
                    slug: '#',
                    image: ArticleTrick,
                    keyword: 'GIFT GUIDE',
                },
            ],
        },
        //9
        {
            associatedDevice: 'galaxy-s22-5g',
            brand: 'Samsung',
            title: 'Top 5 Screen Protectors for Samsung Galaxy S22 ',
            slug: 'top-5-screen-protectors-for-samsung-galaxy-s22',
            author: 'Asurion Mobile+ Team',
            publishDate: '20 April 2022',
            description:
                'Samsung’s three flagship phones pack exciting upgrades and powerful features, but the one thing that stands out the most is its Infinity-O AMOLED Display. All three phones sport a near bezel-less, full-frontal screen.',
            bannerImage: Banner_image_for_samsung_s22_screen_protector,
            bannerImageSourceText: 'Image Source: Otterbox',
            bannerBackgroundGradient: 'linear-gradient(118.74deg, #0A0A0A 10.34%, #373839 100%)',
            articleImage: Samsung_galaxy_s22_screen_protectors,
            introText:
                'If you, like many others, added the Galaxy S22 to the cart because of its gorgeous display, you will want to invest in a screen protector to keep scratches and scuff marks away.<br><br>Here are the 5 best screen protectors for your Samsung Galaxy S22.',
            content: [
                {
                    header: '1 / ESR Gear',
                    title: '<b>1 / ESR Gear<b>: Ultra-thin ',
                    image: SamsungGalaxyS22ScreenProtector_img1,
                    imageSource: 'Image credit: ESR Gear',
                    description:
                        'ESR Gear’s Liquid Skin screen protector is a full-coverage skin protector with a unique “self-healing liquid skin” that makes light scratches and small bubbles disappear over time so that your phone stays scratch-free. The screen protector is also made of ultra-transparent polymer so images and videos remain crystal-clear even after application.<br><br>At 0.15mm thick, ESR Gear’s screen protector is thin enough for you to forget that it is there and is great for users who dislike the texture of a screen protector. Its thinness also means that you can protect every inch of your phone without affecting touch sensitivity and precision so you can game or browse with ease.',
                    moreInfoTitle: 'Get more information here',
                    moreInfoData: [
                        {
                            heading: 'Available for',
                            infoText: 'Galaxy S22, Galaxy S22 Plus, and Galaxy S22 Ultra',
                        },
                        {
                            heading: 'Buy it from',
                            infoText:
                                '<a href="https://www.esrgear.com/">ESR Gear official website</a> or at leading retailers',
                        },
                    ],
                    proTip: '',
                },
                {
                    header: '2 / Spigen',
                    title: '<b>2 / Spigen</b>: With self-healing technology',
                    image: SamsungGalaxyS22ScreenProtector_img2,
                    imageSource: 'Image credit: Spigen',
                    description:
                        'The Spigen NeoFlex Solid Screen Protector offers edge-to-edge coverage for Galaxy S22 and was made to be compatible with all Spigen cases. Made of flexible film, it is equipped with “self-healing technology” to combat scratches and fingerprints and to keep the screen crystal clear. Spigen claims that this screen protector will preserve a clear screen with “zero glares or rainbow effects”.<br><br>For those who want even more protection, Spigen’s Glas.tR Platinum, a liquid adhesive paired with Spigen’s scratch-resistant, 9H hardness 3D tempered glass, is worth considering. Activated by UV light, this screen cover gently coats your screen with a layer of protection while keeping the feel and look of the original screen.',
                    moreInfoTitle: 'Get more information here',
                    moreInfoData: [
                        {
                            heading: 'Available for',
                            infoText: 'Galaxy S22, Galaxy S22 Plus, and Galaxy S22 Ultra',
                        },
                        {
                            heading: 'Buy it from',
                            infoText:
                                '<a href="https://www.spigen.com/">Spigen official website or at leading retailers</a>',
                        },
                    ],
                    proTip: '',
                },
                {
                    header: '3 / Otterbox',
                    title: '<b>3 / Otterbox</b>: For blue light protection',
                    image: SamsungGalaxyS22ScreenProtector_img3,
                    imageSource: 'Image credit: Otterbox',
                    description:
                        'You may know Otterbox for its attractive phone cases, but the brand also produces high-quality screen protectors. The Alpha Flex Blue Light Guard is a screen protector that brings protection to your phone and your eyes with its ability to filter certain wavelengths of light.<br><br>Made of an ultra-strong, glass-like material that doesn’t crack, shatter, or chip, the Alpha Flex Blue Light Guard protects your screen from scratches and scrapes. For an even more well-rounded protection, this screen protector is equipped with antimicrobial technology that protects the surface of the device from microbes. Otterbox also claims that this screen protector will not hamper screen sensitivity and clarity.',
                    moreInfoTitle: 'Get more information here',
                    moreInfoData: [
                        {
                            heading: 'Available for',
                            infoText: 'Galaxy S22, Galaxy S22 Plus, and Galaxy S22 Ultra',
                        },
                        {
                            heading: 'Buy it from',
                            infoText:
                                '<a href="http://otterbox.com.au/">Otterbox official website</a> or at leading retailers',
                        },
                    ],
                    proTip: '',
                },
                {
                    header: '4 / Whitestone',
                    title: '<b>4 / Whitestone Dome</b>: For maximum protection',
                    image: SamsungGalaxyS22ScreenProtector_img4,
                    imageSource: 'Image credit: Whitestone Dome',
                    description:
                        'The Whitestone Dome screen protector is a tempered glass screen protector that contains 5 layers of glass structures to offer maximum protection. It also makes use of a liquid dispersion technology that is designed to keep your phone responsive to the touch. The patented liquid glass evenly disperses over your entire screen, including existing minor scratches or cracks, and hardens to create an impenetrable edge-to-edge barrier while repairing former cracks and imperfections.<br><br>Your purchase even comes with an installation frame and a UV curing light to prevent errors and air bubbles during installation.',
                    moreInfoTitle: 'Get more information here',
                    moreInfoData: [
                        {
                            heading: 'Warranty?',
                            infoText: 'Limited lifetime warranty',
                        },
                        {
                            heading: 'Available for',
                            infoText: 'Galaxy S22, Galaxy S22 Plus, and Galaxy S22 Ultra',
                        },
                        {
                            heading: 'Buy it from',
                            infoText:
                                '<a href="https://www.whitestonedome.com/">www.whitestonedome.com</a> or at leading retailers',
                        },
                    ],
                    proTip: '',
                },
                {
                    header: '5 / UAG',
                    title: '<b>5 / UAG</b>: For keeping fingerprints at bay',
                    image: SamsungGalaxyS22ScreenProtector_img5,
                    imageSource: 'Image credit: UAG',
                    description:
                        'Constructed out of 9H Strength glass, UAG’s tempered glass screen protectors are designed to keep your display free of scratches and abrasions even in the harshest environments. The ultra-thin design provides precise touch sensitivity as well as flawless HD surface clarity. This screen protector is also coated with a unique layer that repels natural skin oils, reducing pesky fingerprints and smudges that those with sweaty palms are often annoyed by.',
                    moreInfoTitle: 'Get more information here',
                    moreInfoData: [
                        {
                            heading: 'Warranty?',
                            infoText: 'Limited lifetime warranty',
                        },
                        {
                            heading: 'Available for',
                            infoText: 'Galaxy S22, Galaxy S22 Plus, and Galaxy S22 Ultra',
                        },
                        {
                            heading: 'Buy it from',
                            infoText:
                                '<a href="https://www.urbanarmorgear.com/">UAG official website</a> or at leading retailers',
                        },
                    ],
                    proTip: '',
                },
            ],
            advertisement: {
                verticalAdImage: AdVertical,
                horizontalAdImage: AdHorizontal,
                mobileAdImage: AdMobile,
                adContent: {
                    asurionLogo: AsurionLogo,
                    line1Desktop: 'New iPhone 13 insurance',
                    line2Desktop: 'Premium starts from',
                    line1Mobile: 'the smart phone insurance.',
                    line2Mobile: '<b>iPhone 13</b> premium starts',
                    line3Mobile: 'from',
                    price: '$5.95',
                    paymentDurationText: '/mth',
                    phoneImageVertical: iPhone13AdImageDesktop,
                    phoneImageHotizontal: iPhone13AdImageHorizontal,
                    phoneImageMobile: iPhone13AdImageMobile,
                    buttonText: 'View phone plans',
                    buttonLink: '',
                    backgroundImageVertical: VerticalAdBackground,
                    backgroundImageHorizontal: HorizontalAdBackground,
                    backgroundImageMobile: MobileAdBackground,
                    legalText:
                        'Product issued by Liberty Mutual Insurance Company. Apple and iPhone are trademarks of Apple, Inc., registered in the U.S. and other countries and regions. Asurion is not affiliated with, or sponsored or endorsed by, Apple.',
                    legalTextMobile:
                        'Issued by Liberty Mutual Insurance Company AFSL 530842. T&Cs apply. Consider the PDS and TMD available online',
                },
            },
            mainKeyword: {
                name: 'Samsung Galaxy S22',
                slug: 'Samsung-Galaxy-S22',
            },
            relatedKeywords: [
                {
                    name: 'Phone screen protectors',
                    slug: 'phone-screen-protectors',
                },
            ],
            footerTitle: 'Apply for Asurion Mobile+ insurance for even more protection!',
            footerDescription:
                "A case can protect your phone from small drops but may not be able to shield your sleek new iPhone 13 from nastier accidents. For more peace of mind, Asurion Mobile+ <i>smart</i> phone insurance can cover your phone for accidental damage, loss, and theft.<br><br>Go to <a  data-cke-saved-href='www.asurion.com.au' href='https://www.asurion.com.au/' target='_blank'>www.asurion.com.au</a> to compare our plans and get extra protection for your phone today.",
            articlesYouMayLike: [
                {
                    title: 'How to Troubleshoot Someone Else’s Phone Remotely',
                    slug: '#',
                },
                {
                    title: '22 Great Wireless Chargers to Refuel Your Phone (or Watch)',
                    slug: '#',
                },
                {
                    title: 'Power Up Anywhere with Our Favorite Portable Chargers',
                    slug: '#',
                },
            ],
            keywords: [
                {
                    name: 'PROTECTION',
                    slug: '#',
                },
                {
                    name: 'ACCESSORIES',
                    slug: '#',
                },
                {
                    name: 'IPHONE',
                    slug: '#',
                },
                {
                    name: 'CASES',
                    slug: '#',
                },
                {
                    name: 'SHOPPING',
                    slug: '#',
                },
                {
                    name: 'HOW-TO',
                    slug: '#',
                },
            ],
            moreArticlesText: 'More phone accessories advice',
            moreArticles: [
                {
                    title: 'Wearables reduce your reliance on a phone while keeping you connected',
                    slug: '#',
                    image: ArticleWristy,
                    keyword: 'WRIST-Y BUSINESS',
                },
                {
                    title: "How to Master Google's AI Phone Call Features",
                    slug: '#',
                    image: ArticleGoogle,
                    keyword: 'HOW-TO',
                },
                {
                    title: 'DuckDuckGo Wants to Stop Apps From Tracking You on Android',
                    slug: '#',
                    image: ArticleDuck,
                    keyword: 'PRIVACY',
                },
                {
                    title: 'Trick Out Your iPhone12 with These Cases and Accessories',
                    slug: '#',
                    image: ArticleTrick,
                    keyword: 'GIFT GUIDE',
                },
            ],
        },
    ],
    facebookLink: 'https://www.facebook.com/asurionau/',
    youtubeLink: 'https://www.youtube.com/channel/UCMdBvWUCUAfoOGxnOlSyo3g',
    linkedInLink: 'https://www.linkedin.com/company/asurion/',
    getMoreInfoText: 'Get more information here',
};
